import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { Mic, MicOff } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { CallContext } from '../../../contexts/callContext';
import RestApiContext from '../../../contexts/restApiContext';
import { useStyles } from './recordingControl.styles';

const errors = {
  pause: 'We could not pause the recording.',
  resume: 'We could not resume the recording.',
};

const ToggleRecording = (): JSX.Element => {
  const [recording, setRecording] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const api = useContext(RestApiContext);
  const { contact } = useContext(CallContext);

  const classes = useStyles();

  const buttonProps: ButtonProps = {
    disabled: loading,
    onClick: () => toggleRecording(!recording),
    color: 'primary',
    startIcon: recording ? <MicOff /> : <Mic />,
    variant: 'contained',
    size: 'small',
    fullWidth: true,
  };

  const toggleRecording = async (record: boolean) => {
    setLoading(true);
    try {
      record ? await api.resumeRecording(contact) : await api.pauseRecording(contact);
      setRecording(record);
    } catch (e) {
      const message = record ? errors.resume : errors.pause;
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <>{error}</>;
  }

  return (
    <>
      <section className={classes.recordingButton}>
        {loading && <CircularProgress size={20} className={classes.spinner} />}
        <Button {...buttonProps}>{(recording && 'Pause') || 'Resume'} Recording</Button>
      </section>
    </>
  );
};

export default ToggleRecording;
