import React, { Profiler, ReactElement, useContext, useEffect, useState } from 'react';
import { Accordion, Icon, Menu } from 'semantic-ui-react';
import { CallContext } from '../../contexts/callContext';
import { useDataContextProvider } from '../../contexts/dataContext';
import { DialerContextProvider } from '../../contexts/dialerContext';
import AgentTransfer from './agentTransfer';
import { CallInfo } from './callInfo';
import CallLog from './callLog';
import { Disposition } from './disposition';
import { OutboundCalls } from './outboundCalls';
import { PhoneDirectory } from './phoneDirectory';
import QueueMetrics from './queueMetrics';
import { RecentCalls } from './recentCalls';
import { logProfilerData } from '../../utils/profilerUtils';
import AppContext from '../../contexts/appContext';
import CollapsibleCcpContext from '../CollapsibleCcp/CollapsibleCcpContext';
import PaylineTransfer from './paylineTransfer';
import ToggleRecording from './recordingControls/recordingControl';

interface AccordionComponent {
  label: string;
  component: ReactElement;
  accordion_index: number;
  shouldDisplay: () => boolean;
}

/**
 * This panel will be displayed below the CCP iFrame
 */
export function SideMenu() {
  const [displayRecordingControls, setDisplayRecordingControls] = useState<boolean>();
  const { agents, queues, callLog } = useDataContextProvider();
  const { contact, onACW } = useContext(CallContext);
  const { config } = useContext(AppContext);
  const context = useContext(CollapsibleCcpContext);

  useEffect(() => {
    if (!contact) {
      return;
    }

    contact.onConnected(() => {
      const { callRecordingEnabled } = contact.getAttributes();

      setDisplayRecordingControls(callRecordingEnabled?.value !== 'false');
    });
    contact.onEnded(() => {
      setDisplayRecordingControls(false);
    });
  }, [contact]);

  const handleClick = (_e: any, titleProps: any) => {
    const newIndex = context.sideMenuActiveIndex === titleProps.index ? -1 : titleProps.index;
    context.setSideMenuActiveIndex(newIndex);
  };

  const buildAccordion = () => {
    const recordingControlIndex = context.showRecordingControlsIndex();
    const items: AccordionComponent[] = [
      {
        label: 'Recording controls',
        component: <ToggleRecording />,
        accordion_index: recordingControlIndex,
        shouldDisplay: () => recordingControlIndex >= 0 && displayRecordingControls,
      },
      {
        label: 'Call Details',
        component: <CallInfo />,
        accordion_index: context.showCallDetailsIndex(),
        shouldDisplay: () => {
          return context.showCallDetailsIndex() >= 0;
        },
      },
      {
        label: 'Phone Directory',
        component: (
          <Profiler id="PhoneDirectory" onRender={logProfilerData}>
            <PhoneDirectory />
          </Profiler>
        ),
        accordion_index: context.showPhoneDirectoryIndex(),
        shouldDisplay: () => {
          return context.showPhoneDirectoryIndex() >= 0;
        },
      },
      {
        label: 'Manual Outbound Calls',
        component: (
          <Profiler id="OutboundCalls" onRender={logProfilerData}>
            <OutboundCalls />
          </Profiler>
        ),
        accordion_index: context.showManualOutboundCallIndex(),
        shouldDisplay: () => {
          return context.showManualOutboundCallIndex() >= 0;
        },
      },
      {
        label: `Agent Transfer (${agents.length})`,
        component: <AgentTransfer agents={agents} />,
        accordion_index: context.showAgentLogIndex(),
        shouldDisplay: () => {
          return context.showAgentLogIndex() >= 0;
        },
      },
      {
        label: `Queue Metrics (${queues.length})`,
        component: <QueueMetrics queues={queues} />,
        accordion_index: context.showQueueMetricsIndex(),
        shouldDisplay: () => {
          return context.showQueueMetricsIndex() >= 0;
        },
      },
      {
        label: 'Call History',
        component: <RecentCalls />,
        accordion_index: context.showCallHistoryIndex(),
        shouldDisplay: () => {
          return context.showCallHistoryIndex() >= 0;
        },
      },
      {
        label: `Call Log (${callLog.length})`,
        component: <CallLog callLog={callLog} />,
        accordion_index: context.showCallLogIndex(),
        shouldDisplay: () => {
          return context.showCallLogIndex() >= 0;
        },
      },
      {
        label: `Agent-assisted Payment`,
        component: <PaylineTransfer />,
        accordion_index: context.showPaylineTransferIndex(),
        shouldDisplay: () => context.showPaylineTransferIndex() >= 0,
      },
    ];

    return items
      .filter(item => item.shouldDisplay())
      .map((item, index) => (
        <Menu.Item key={`component-${item.accordion_index}`}>
          <Accordion.Title
            active={context.sideMenuActiveIndex === item.accordion_index}
            index={item.accordion_index}
            onClick={handleClick}>
            <Icon name="dropdown" />
            {item?.label}
          </Accordion.Title>
          <Accordion.Content
            active={context.sideMenuActiveIndex === item.accordion_index}
            content={item?.component}></Accordion.Content>
        </Menu.Item>
      ));
  };

  return (
    <React.Fragment>
      {config.disposition.enabled && onACW && <Disposition />}
      {/* <Profiler id="Accordian" onRender={logProfilerData}> */}
      <DialerContextProvider>
        <Accordion as={Menu} vertical>
          {buildAccordion()}
        </Accordion>
      </DialerContextProvider>
      {/* </Profiler> */}
    </React.Fragment>
  );
}
