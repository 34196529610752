import AppBar from '@material-ui/core/AppBar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import 'amazon-connect-streams';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { Image } from 'semantic-ui-react';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext';
import { ConnectDataContextProvider } from '../../contexts/dataContextProvider';
import { getLogger } from '../../services/loggingService';
import { AppVersion } from '../base/appVersion';
import { Ccp } from '../ccp';
import ScreenPopContainer from '../ScreenPopContainer/screenPopContainer';
import { SideMenu } from '../SideMenu/sideMenu';
import { LogoutButton } from '../base/logoutButton';
import { VoicemailButton } from '../base/voicemailButton';
import {
  AnswerCallButton,
  CallDetailsButton,
  CallHistoryButton,
  EndCallButton,
  ExpandCcpButton,
  HoldButton,
  MetricsButton,
  MuteButton,
  PeerStatusButton,
  PhoneDirectoryButton,
  QueueMetricsButton,
} from './MenuButtons';
import CollapsibleCcpContext, { CollapsibleCcpContextProvider, ICollapsibleCcpContext } from './CollapsibleCcpContext';

const logger = getLogger('CCPWrapper');

const drawerWidth = 350;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100vw',
    padding: '0 0 0 0 !important',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#F2F2F2',
  },
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    width: '280px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    margin: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  wrapperOpen: {
    width: '334px !important',
    height: '100% !important',
  },
  wrapperClosed: {
    height: '1px',
    width: '1px',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    backgroundColor: '#4786A9',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: '#4786A9',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  bottomLogout: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 500,
    zIndex: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: '#4786A9',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  my10: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  ccpContainer: {
    display: 'flex',
    height: '100% !important',
    justifyContent: 'center',
    backgroundColor: '#4786A9',
  },
  whiteText: {
    color: 'white',
  },
  screenpopContainer: {
    flex: 1,
    padding: '150 0 0 0 !important',
  },
}));

/**
 *
 * @returns Provides Collapsible Menu Feature
 */
const CollapsibleCcp = () => {
  const classes = useStyles();

  const [incoming, setIncoming] = React.useState(false);
  const [connecting, setConnecting] = React.useState(false);
  const [connected, setConnected] = React.useState(false);
  const [muted, setMuted] = React.useState(false);
  const [onHold, setOnHold] = React.useState(false);

  const { open, setOpen } = useContext(CollapsibleCcpContext);

  const { setAlert } = useContext(AppContext);

  const { agent, contact } = useContext(CallContext);

  React.useEffect(() => {
    if (agent) {
      agent.onMuteToggle(obj => {
        setMuted(obj.muted);
      });
    }
  }, [agent]);

  React.useEffect(() => {
    if (contact) {
      contact.onConnected(() => {
        setConnected(true);
        setIncoming(false);
        setConnecting(false);
        setAlert('', 'success');
      });
      contact.onIncoming(() => {
        setIncoming(true);

        const number = contact.getActiveInitialConnection()?.getEndpoint().phoneNumber;
        setAlert(number ? `Incoming call from ${number}` : 'Incoming call ...', 'success');
      });
      contact.onConnecting(() => {
        setConnecting(true);
      });
      contact.onPending(() => {});
      contact.onEnded(() => {
        setConnected(false);
        setConnecting(false);
        setIncoming(false);
      });
      contact.onRefresh(() => {
        setOnHold(contact.getAgentConnection().isOnHold());
      });
    }
  }, [contact]);

  const toggleMute = (newBool: boolean) => {
    logger.debug('Toggling mute from to ', muted, newBool);
    if (!agent) return;
    if (newBool) {
      agent.mute();
    } else {
      agent.unmute();
    }
  };

  const toggleHold = (newBool: boolean) => {
    if (!contact) return;
    const onHold = contact.getAgentConnection().isOnHold();
    if (newBool && !onHold) {
      contact.getActiveInitialConnection()?.hold();
      setOnHold(true);
    } else if (!newBool && onHold) {
      contact.getActiveInitialConnection()?.resume();
      setOnHold(false);
    }
  };

  /*   
        Dynamically Configure the tabs shown in the Side Panel
    */

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}>
            <ArrowForwardIcon style={{ color: '#4786A9' }} />
          </IconButton>
          <div className={classes.logoContainer}>
            <div className={classes.logo}>
              <Image src="top-logo.svg" size="small" />
            </div>
          </div>
          <ButtonGroup variant="contained">
            <VoicemailButton />
            <LogoutButton disabled={connected || connecting || incoming} />
          </ButtonGroup>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          'hide-scrollbar': true,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <div className={classes.toolbar}>
          <IconButton className={clsx(classes.whiteText)} onClick={() => setOpen(false)}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
        <Divider />
        {!open && (
          <List>
            {(connecting || incoming) && <AnswerCallButton />}
            {connected && (
              <>
                <EndCallButton />
                <MuteButton active={muted} onClick={() => toggleMute(!muted)} />
                <HoldButton active={onHold} onClick={() => toggleHold(!onHold)} />
              </>
            )}
            {!(connecting || incoming) && !connected && <ExpandCcpButton />}
            <Divider className={classes.my10} />
            <PhoneDirectoryButton />
            <QueueMetricsButton />
            <PeerStatusButton />
            <MetricsButton />
            <CallDetailsButton />
            <CallHistoryButton />
          </List>
        )}

        <div className={clsx(classes.ccpContainer)}>
          <div
            className={clsx({
              [classes.wrapperOpen]: open,
              [classes.wrapperClosed]: !open,
            })}>
            {<Ccp collapsed={!open} />}
            {agent && open && (
              <ConnectDataContextProvider>
                <SideMenu />
              </ConnectDataContextProvider>
            )}
            {open && <AppVersion />}
          </div>
        </div>
      </Drawer>
      <ScreenPopContainer drawerOpen={open} />
    </div>
  );
};

const withProvider = () => (
  <CollapsibleCcpContextProvider>
    <CollapsibleCcp />
  </CollapsibleCcpContextProvider>
);

export default withProvider;
