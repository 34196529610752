import React, { useEffect, useState } from 'react';
import ConfigurationService from '../services/configurationService';
import { getLogger } from '../services/loggingService';
import { CcpAlert } from '../models/ccpAlert';
import { CcpSnackbar } from '../components/base/ccpSnackbar';
import { Color } from '@material-ui/lab/Alert';
import { CallType } from '../models/screenPopConfig';

const logger = getLogger('AppContextProvider');

export interface IAppContext {
  tabsLastUpdated: number;
  togglePaymentTab: (tabLabel: CallType, enabled: boolean) => void;
  config: ConfigurationService;
  setAlert: (message: string, type: Color, timeout?: number) => void;
  voicemailOpen: boolean;
  setVoicemailOpen: (val: boolean) => void;
}

export const AppContext = React.createContext<IAppContext>(null);

export function AppContextProvider(props: { children: any }) {
  const [status, setStatus] = useState('Loading');
  const [alert, setAlert] = useState<CcpAlert>();
  const [config] = useState(new ConfigurationService());
  const [tabsLastUpdated, setTabsLastUpdated] = useState<number>(0);
  const [voicemailOpen, setVoicemailOpen] = useState(false);

  const togglePaymentTab = (connectionType: CallType, enabled: boolean) => {
    !enabled ? config.disabledPaymentIframe(connectionType) : config.enablePaymentIframe(connectionType);
    setTabsLastUpdated(Date.now());
  };
  useEffect(() => {
    (async () => {
      try {
        await config.load();
        setStatus('Ready');
      } catch (error) {
        logger.error(`Configuration Failed: ${error.message}`, error);
        setStatus(error.message);
      }
    })();
  }, [config]);

  if (status !== 'Ready') {
    return (
      <div>
        <p>{status}</p>
      </div>
    );
  }

  const ctx: IAppContext = {
    tabsLastUpdated,
    togglePaymentTab,
    config,
    voicemailOpen,
    setAlert: (message, type, timeout) => setAlert(new CcpAlert(message, type, timeout)),
    setVoicemailOpen,
  };

  return (
    <AppContext.Provider value={ctx}>
      {alert && <CcpSnackbar alert={alert} clear={() => setAlert(null)} />}
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContext;
