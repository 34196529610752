import 'amazon-connect-streams';
import { useContext, useEffect, useMemo, useState } from 'react';
import { IUser } from '../models/websocketInterfaces';
import { getLogger } from '../services/loggingService';
import AppContext from './appContext';
import { CallContext } from './callContext';

const logger = getLogger('CallContextProvider');

/**
 * Manages the connect initialization and tracks contact/agent state in a central place.
 */
const CallContextProvider = (props: { children: any }) => {
  const idRegex: RegExp = new RegExp(/\((\w+)\)/);

  const [initialized, setInitialized] = useState(false);
  const [voiceContact, setVoiceContact] = useState<connect.Contact | undefined>(undefined);
  const { config, setAlert } = useContext(AppContext);

  const [agent, setAgent] = useState<connect.Agent | undefined>(undefined);
  const [defaultQueueName, setDefaultQueueName] = useState<string>('');
  const [availableAgentEndpoints, setAvailableAgentEndpoints] = useState<connect.Endpoint[]>([]);
  const [availableQueueEndpoints, setAvailableQueueEndpoints] = useState<connect.Endpoint[]>([]);
  const [user, setUser] = useState<IUser>({
    name: '',
    username: '',
    routingProfile: '',
    queues: [],
  });

  const [currentClientIdentifier, setCurrentClientIdentifier] = useState<string>('');
  const [contactData, setContactData] = useState<any>('');
  const [onACW, setOnACW] = useState<boolean>(false);

  const initialize = (container: HTMLElement) => {
    if ((connect.core as any).initialized) {
      logger.debug('ccp already initialized');
      return;
    }

    logger.debug('init ccp called');
    const params: connect.InitCCPOptions = {
      ccpUrl: config.ccpUrl,
      loginPopup: true,
      loginPopupAutoClose: true,
      region: config.region,
      softphone: {
        allowFramedSoftphone: true,
      },
      pageOptions: {
        enableAudioDeviceSettings: config.enableAudioDeviceSettings === 'true',
        enablePhoneTypeSettings: config.enablePhoneTypeSettings === 'true',
      },

      // Configure SSO
      loginUrl: config.samlSsoUrl || undefined,
    };

    connect.core.initCCP(container, params);

    connect.contact((contact: connect.Contact) => {
      logger.debug('contact id ' + contact.getContactId());
      setContact(contact);
    });

    connect.agent((agent: connect.Agent) => {
      setAgent(agent);
      agent.onError((a: any) => logger.error('onAgentError', a));
      agent.onSoftphoneError(onSoftphoneError);
      setInitialized(true);
    });
  };

  useEffect(() => {
    // this will set up user state
    handleAgentChange(agent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  const terminate = () => connect.core.terminate();

  const onSoftphoneError = (e: connect.SoftphoneError) => {
    logger.error('onSoftphoneError', e);
    if (e.errorType === 'unsupported_browser') {
      setAlert(
        'Only the latest 3 versions of Chrome or Firefox are supported. Upgrade your browser to resolve this error.',
        'error'
      );
    } else if (e.errorType === 'microphone_not_shared') {
      setAlert(
        'The microphone is not accessible, please reload the page and allow microphone access when prompted.',
        'error'
      );
    } else if (e.errorType === 'user_busy_error') {
      // Dont display busy error
      setAlert('', 'success');
    } else {
      setAlert(`${e.errorType}: ${e.errorMessage}`, 'error');
    }
  };

  const uiBlockerID = 'block-clear-contact';

  const handleAgentChange = (agent: connect.Agent | undefined) => {
    if (!agent) return;
    logger.debug('AGENT CHANGE::Subscribing to Connect Agent Events', agent);
    agent.getEndpoints(agent.getAllQueueARNs(), {
      success: data => {
        logger.debug('Agent Data Endpoints and all that: ', data);
        const eps_agent = data.endpoints.filter(ep => ep.type === 'agent');
        const eps_queue = data.endpoints.filter(ep => ep.type === 'queue');
        logger.debug('queues ', eps_queue);
        setAvailableAgentEndpoints(eps_agent);
        setAvailableQueueEndpoints(eps_queue);
      },
      failure: function () {},
    });
    const agentConfig = agent.getConfiguration();
    // console.log("Agent configuration: ", agentConfig);
    const userAgentDetails: IUser = {
      name: agentConfig.name,
      username: agentConfig.username,
      routingProfile: agentConfig.routingProfile.name,
      queues: agentConfig.routingProfile.queues.map(queue => queue.name),
    };
    console.log({ userAgentDetails, queues: agentConfig.routingProfile.queues });

    setUser(userAgentDetails);

    const {
      defaultOutboundQueue: { name },
    } = agent.getRoutingProfile();

    logger.debug('Get Routing Profile Default Queue ', name);
    if (defaultQueueName !== name) {
      setDefaultQueueName(name);
      // if (!currentQueueName) {
      //     setCurrentQueueName(name);
      // }
    }
    // setDefaultClientIdentifierByRoutingProfileQueue(name)
    agent.onAfterCallWork(() => {
      (window as any).document.getElementById(uiBlockerID).style = undefined;
      logger.debug('On after call work, blocking "clear contact" for 3 seconds');
      setTimeout(() => {
        (window as any).document.getElementById(uiBlockerID).style.display = 'none';
      }, 3000);
    });
  };

  const setContactState = (contact: connect.Contact) => {
    const newContactId = contact.getInitialContactId();
    const currentQueue = contact.getQueue();
    setContactData({
      contactId: newContactId,
      currentQueueName: currentQueue.name,
    });
  };

  // define logic to identify customers
  // this example is looking for the customer name
  // as a word surrounded by parentheses in the Queue Name
  const getClientIdentifier = (name: string) => {
    const match = name.match(idRegex);
    if (match && match.length > 0) {
      const clientIdentifier = match[1];
      return clientIdentifier;
    } else {
      return 'default';
    }
  };

  // chat coming soon
  const setContact = (newContact: connect.Contact | undefined): void => {
    if (!newContact) return;
    console.log('CALLCONTEXT::Contact Changed: ' + newContact.getContactId());
    if (
      newContact.getType() === connect.ContactType.VOICE ||
      newContact.getType() === connect.ContactType.QUEUE_CALLBACK
    ) {
      setVoiceContact(newContact);
    }
    // set Client Identifier -
    // this will handle customers that have data defined for multiple clients/divisions/tenants
    setCurrentClientIdentifier(getClientIdentifier(newContact.getQueue().name));

    //For initial logins and page refreshes?
    if ([connect.ContactStateType.ENDED, connect.ContactStateType.CONNECTED].includes(newContact.getStatus().type)) {
      logger.debug('CONTACT:STATE:ENDED-CONNECTED', newContact.getStatus().type, newContact);
      setContactState(newContact);
    }

    newContact.onConnected(() => {
      logger.debug('CONTACT:STATE:CONNECTED', newContact.getStatus().type, newContact);
      setContactState(newContact);
    });

    newContact.onEnded(() => {
      logger.debug('CONTACT:STATE:ENDED', newContact.getStatus().type, newContact);
    });
    newContact.onDestroy(() => {
      logger.debug('CONTACT:STATE:DESTROY', newContact);
      setVoiceContact(undefined);
      setContactData((current: any) => {
        return { ...current, currentQueueName: defaultQueueName };
      });
      setOnACW(false);
    });
    newContact.onConnecting(() => {
      console.log('CONTACT:CONNECTING');
    });
    newContact.onMissed(() => {
      console.log('CONTACT:MISSED');
    });

    newContact.onACW(() => {
      console.log('CONTACT::ON AFTER CALL');
      setOnACW(true);
    });
  };

  const returnContact = useMemo(
    () => ({
      contact: voiceContact,
      currentClientIdentifier,
      contactId: contactData.contactId,
      currentQueueName: contactData.currentQueueName,
      onACW,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [voiceContact, currentClientIdentifier, contactData, onACW]
  );

  const returnAgent = useMemo(
    () => ({
      agent,
      user,
      availableAgentEndpoints,
      availableQueueEndpoints,
    }),
    [agent, user, availableAgentEndpoints, availableQueueEndpoints]
  );

  return (
    <CallContext.Provider value={{ initialize, initialized, terminate, ...returnContact, ...returnAgent }}>
      {props.children}
    </CallContext.Provider>
  );
};

CallContext.displayName = 'CallContextProvider'; // used in dev tools
export { CallContextProvider };
