import { Box, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dropdown, Message } from 'semantic-ui-react';
import { CallContext } from '../../contexts/callContext';
import { useDialerContextProvider } from '../../contexts/dialerContext';
import { DialerSource } from '../../models/dialer';
import { getLogger } from '../../services/loggingService';
import { AppToolTip } from '../base/appToolTip';
import DialerButton from './dialerButton';

const logger = getLogger('OutboundCalls');

interface IState {
  availableQueues: any;
  outboundQueue: any;
  outboundNumber: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  callButton: {
    marginTop: '10px',
  },
}));

export function OutboundCalls() {
  const classes = useStyles();
  const { agent, currentQueueName } = useContext(CallContext);
  const { setDialerProps } = useDialerContextProvider();

  const [availableQueues, setAvailableQueues] = useState<any[]>([]);
  const [dialerNumber, setDialerNumber] = useState<string>('');
  const [dialerQueue, setDialerQueue] = useState<string>('');

  useEffect(() => {
    if (!agent) return;

    getAvailableQueues(agent);
    setCountryCodes(agent);
  }, [agent, currentQueueName]);

  const setCountryCodes = (agent: connect.Agent): void => {
    logger.debug('OutboundCalls::Getting available country codes ...');
    try {
      const countries = agent.getDialableCountries();
      logger.debug('OutboundCalls::Countries ', countries);
    } catch (err) {
      logger.error('OutboundCalls::Error retrieving countries');
    }
  };

  const getAvailableQueues = (agent: connect.Agent): void => {
    logger.debug('OutboundCalls::Getting available queues ...');
    try {
      const { queues, name } = agent.getRoutingProfile();
      logger.debug(
        'Queues',
        queues.map(q => q.name),
        name
      );
      setAvailableQueues(normalizeQueuesForList(queues));
    } catch (err) {
      logger.debug('OutboundCalls::No available queues');
    }
  };

  const normalizeQueuesForList = (queues: any): any[] => {
    return queues
      .filter((queue: any) => !!queue.name)
      .map((queue: any, i: number) => ({
        key: `queue-${i + 1}`,
        text: queue.name,
        value: queue.queueARN,
      }));
  };

  useEffect(() => {
    setDialerProps({
      source: DialerSource.OutboundCalls,
      number: '+' + dialerNumber,
      immediateDial: false,
      queueArn: dialerQueue,
      clearCall: clearCall,
    });
  }, [dialerNumber, dialerQueue]);

  const handleQueueSelectionChange = (event: any, data: any): void => {
    setDialerQueue(data.value);
  };

  const handleOutboundNumber = (event: any) => {
    // allow digits, x, and comma
    const value = event.target.value.replace(/[^0-9x,]/g, '');
    setDialerNumber(value);
  };

  const clearCall = useCallback(() => {
    setDialerNumber('');
    setDialerQueue('');
  }, []);

  if (availableQueues.length === 0) {
    return <Message warning content="No available queues." />;
  }

  return (
    <div id="outbound-call-options">
      <TextField
        label="Outgoing Number"
        id="outlined-margin-dense"
        className="phoneInputRow"
        onChange={handleOutboundNumber}
        InputProps={{
          startAdornment: <InputAdornment position="start">+</InputAdornment>,
          endAdornment: (
            <InputAdornment position="end">
              <AppToolTip
                text="Use an 'x' to indicate extension, and use commas for a 1 sec pause. i.e. 12223334444x,,1,2"
                placement={'bottom-end'}
              />
            </InputAdornment>
          ),
        }}
        margin="dense"
        variant="outlined"
        value={dialerNumber}
      />
      <Dropdown
        placeholder="Select Outgoing Queue"
        fluid
        search
        selection
        value={dialerQueue}
        options={availableQueues}
        onChange={handleQueueSelectionChange}
      />
      <Box>
        <DialerButton classes={classes} />
      </Box>
    </div>
  );
}
