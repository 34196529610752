import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import { blue, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CallIcon from '@material-ui/icons/Call';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Alert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext';
import { IAgent } from '../../models/websocketInterfaces';

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[500],
    },
  },
});

interface IProps {
  agents: IAgent[];
}
const useStyles = makeStyles({
  table: {
    maxWidth: 330,
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      padding: '0',
    },
  },
});

function Row(props: { agent: IAgent; quickConnectEP: connect.Endpoint }) {
  const { agent, quickConnectEP } = props;
  const [open, setOpen] = React.useState(false);
  const callContext = useContext(CallContext);
  const { config } = useContext(AppContext);
  const classes = useRowStyles();
  const onCallFailure = (err: any): void => {
    err = JSON.parse(err);
    console.log(err);
  };
  const onCallSuccess = (): void => {
    console.log('SUCCESSFUL TRANSFER');
    const transferTabIndex = config.screenPop.displayConfig.tabConfig.findIndex(tab => {});

    if (transferTabIndex) {
      // update the config settings in the service
    }
  };

  const transferToAgentEndpoint = async (qcEp: connect.Endpoint) => {
    if (!callContext.agent) return;
    const agentContacts = callContext.agent.getContacts(connect.ContactType.VOICE);
    if (agentContacts.length === 0) {
      return;
    } else if (agentContacts.length === 1) {
      agentContacts[0].addConnection(qcEp, {
        success: onCallSuccess,
        failure: onCallFailure,
      });
    } else {
      throw new Error('Already have 2 active voice contacts');
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {agent.FirstName} {agent.LastName}
        </TableCell>
        <TableCell align="right">
          {quickConnectEP && agent.Status === 'Available' ? (
            <Chip
              size="small"
              color="secondary"
              onClick={() => transferToAgentEndpoint(quickConnectEP)}
              icon={quickConnectEP ? <CallIcon /> : <NotInterestedIcon />}
              label={agent.Status.charAt(0).toUpperCase() + agent.Status.slice(1).toLowerCase()}
            />
          ) : (
            <Chip
              size="small"
              label={agent.Status.charAt(0).toUpperCase() + agent.Status.slice(1).toLowerCase()}
              disabled
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Button
                disabled={!quickConnectEP || agent.Status !== 'Available'}
                onClick={() => transferToAgentEndpoint(quickConnectEP)}
                size="small"
                variant="contained"
                color="primary">
                {!quickConnectEP || agent.Status !== 'Available' ? 'Cannot transfer' : 'Transfer Call'}
              </Button>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow className={classes.root}>
                    <TableCell>Quick Connect Linked:</TableCell>
                    <TableCell>{quickConnectEP ? 'YES' : 'NOT LINKED'}</TableCell>
                  </TableRow>
                  <TableRow className={classes.root}>
                    <TableCell>Active Queue:</TableCell>
                    <TableCell>
                      {['CONNECTED', 'CONNECTING'].includes(agent.Status.toUpperCase()) ? agent.Queue : 'NA'}
                    </TableCell>
                  </TableRow>
                  {agent.HierarchyLevel1 ? (
                    <TableRow className={classes.root}>
                      <TableCell>Team:</TableCell>
                      <TableCell>{agent.HierarchyLevel2 ? agent.HierarchyLevel2 : 'NA'}</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
interface IAvailableAgentEPDict {
  [name: string]: connect.Endpoint;
}
const AgentTransfer = ({ agents }: IProps): JSX.Element => {
  const classes = useStyles();
  const [availableAgentEPDict, setAvailableAgentEPDict] = useState<IAvailableAgentEPDict>({});
  const { availableAgentEndpoints } = useContext(CallContext);

  useEffect(() => {
    if (availableAgentEndpoints.length === 0) {
      setAvailableAgentEPDict({});
    } else {
      const newDict = availableAgentEndpoints.reduce((dict: IAvailableAgentEPDict, ep) => {
        const key: string = ep.name;
        dict[key] = ep;
        return dict;
      }, {});
      setAvailableAgentEPDict(newDict);
    }
  }, [availableAgentEndpoints]);

  // const availableQuickConnectForAgent = (userName: string) =>
  //     Object.prototype.hasOwnProperty.call(availableAgentEPDict, userName);
  const getQuickConnectEndpoint = (userName: string) => availableAgentEPDict[userName];
  function renderRows(agents: IAgent[]): JSX.Element[] {
    return agents.map((agent: IAgent, i: number) => {
      return (
        <Row
          key={agent.AgentUsername + i}
          agent={agent}
          quickConnectEP={getQuickConnectEndpoint(agent.AgentUsername)}
        />
      );
    });
  }

  return (
    <ThemeProvider theme={theme}>
      {agents.length === 0 ? (
        <Alert severity={'info'}>No Available Agents</Alert>
      ) : (
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="available agent table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRows(agents)}</TableBody>
          </Table>
        </TableContainer>
      )}
    </ThemeProvider>
  );
};

export default AgentTransfer;
