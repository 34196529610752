export class Logger {
  private logTrace: boolean = true;

  constructor(private name: string) {}

  debug(message: string, ...properties: any[]) {
    if (this.logTrace) {
      if (properties.length) {
        console.debug(`${this.name}: ${message}`, ...properties);
      } else {
        console.debug(`${this.name}: ${message}`);
      }
    }
  }

  error(message: string, ...properties: any[]) {
    if (properties.length) {
      console.error(`${this.name}: ${message}`, ...properties);
    } else {
      console.error(`${this.name}: ${message}`);
    }
  }

  errorObj(error: unknown) {
    console.error(this.name, JSON.stringify(error, Object.getOwnPropertyNames(error)));
  }
}

const loggers: { [name: string]: Logger } = {};

export const getLogger = (serviceName: string): Logger => {
  if (!loggers[serviceName]) {
    loggers[serviceName] = new Logger(serviceName);
  }
  return loggers[serviceName];
};
