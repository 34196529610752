import React, { useContext } from 'react';
import { IAttributes } from '../models/attributes';
import { ApplicationError } from '../models/applicationError';
import { IPhoneDirItem } from '../models/websocketInterfaces';
import { getLogger } from '../services/loggingService';
import { AppContext } from './appContext';
import TokenContext from './tokenContext';

const logger = getLogger('ApiContext');

interface IRestApiContext {
  getTenantDirectoryByQueue: (clientIdentifier: string, queueName: string) => Promise<IPhoneDirItem[]>;
  getTenantDispositionsByQueue: (clientIdentifier: string, queueName: string) => Promise<string[]>;
  updateContactAttributes: (initialContactId: string, attributes: IAttributes) => Promise<void>;
  getPaylineSignedUrl: (attributes: IAttributes) => Promise<{ signedUrl: string }>;
  pauseRecording: (contact: connect.Contact) => Promise<void>;
  resumeRecording: (contact: connect.Contact) => Promise<void>;
}
export const RestApiContext = React.createContext<IRestApiContext>(null);

export const RestApiContextProvider = (props: { children: any }) => {
  const {
    config: { apiUrl, isPaylineSSO },
  } = useContext(AppContext);
  const { token } = useContext(TokenContext);

  /**
   * Abstracts API access, dont use directly, create helper methods.
   */
  const post = async (endpoint: string, body: object): Promise<Response> => {
    if (!token) {
      logger.error(`API call to ${endpoint} failed because CCP Token is missing`);
      throw new ApplicationError(401, 'CCP Token is missing');
    }

    try {
      return await fetch(apiUrl + endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
    } catch (error) {
      throw new ApplicationError(0, error.message);
    }
  };

  // The local storage token is out of date and not changing with the rest of the call data
  const getTenantDirectoryByQueue = async (clientIdentifier: string, queueName: string): Promise<IPhoneDirItem[]> => {
    const body = {
      clientIdentifier,
      queueName,
    };
    const response = await post('/getPhoneDirectory', body);
    if (response.status === 200) {
      if (!response) {
        return [];
      }
      return response.json();
    } else if (response.status >= 400 && response.status < 500) {
      console.log('400 Error: wait for token refresh on new contact');
      return [];
    } else {
      throw new ApplicationError(response.status, await response.text());
    }
  };

  const getTenantDispositionsByQueue = async (clientIdentifier: string, queueName: string): Promise<string[]> => {
    const body = {
      clientIdentifier,
      queueName,
    };
    const response = await post('/getDispositions', body);
    if (response.status === 200) {
      if (!response) {
        return [];
      }
      return response.json();
    } else if (response.status >= 400 && response.status < 500) {
      console.log('400 Error: wait for token refresh on new contact');
      return [];
    } else {
      throw new ApplicationError(response.status, await response.text());
    }
  };

  const updateContactAttributes = async (initialContactId: string, attributes: IAttributes): Promise<void> => {
    const body = {
      initialContactId: initialContactId,
      attributes,
    };
    const response = await post('/updateAttributes', body);
    if (response.status !== 200) {
      throw new ApplicationError(response.status, await response.text());
    }
  };

  const getPaylineSignedUrl = async (attributes: IAttributes): Promise<{ signedUrl: string }> => {
    const paylinePath = isPaylineSSO ? '/payline-sso-signature' : '/payline-signature';
    const response = await post(paylinePath, { attributes });

    if (response.status !== 200) {
      throw new ApplicationError(response.status, await response.text());
    } else {
      return response.json();
    }
  };

  const pauseRecording = async (contact: connect.Contact) => {
    const contactId = contact?.getContactId();

    await post('/pauseRecording', { contactId });
  };

  const resumeRecording = async (contact: connect.Contact) => {
    const contactId = contact?.getContactId();

    await post('/resumeRecording', { contactId });
  };

  const ctx: IRestApiContext = {
    getTenantDirectoryByQueue,
    getTenantDispositionsByQueue,
    updateContactAttributes,
    getPaylineSignedUrl,
    pauseRecording,
    resumeRecording,
  };

  return <RestApiContext.Provider value={ctx}>{props.children}</RestApiContext.Provider>;
};

export default RestApiContext;
