import { createContext, useContext } from 'react';
import { IContactItem } from '../../models/contactDict';

export enum DISPLAY_SOURCE {
  SINGLE_URL = 'SINGLE_URL',
  TAB_CONFIG = 'TAB_CONFIG',
}

export interface IScreenPopContext {
  outerTabId: number;
  innerTabId: number;
  activeTab: string;
  setInnerTabId: (id: number) => void;
  setOuterTabId: (id: number) => void;
  contactDict: Map<string, IContactItem>;
}

const ScreenPopContext = createContext<IScreenPopContext>({
  outerTabId: 0,
  innerTabId: 0,
  activeTab: '0_0',
  setInnerTabId: (id: number) => {},
  setOuterTabId: (id: number) => {},
  contactDict: new Map<string, IContactItem>(),
});
export default ScreenPopContext;

export const useScreenPopContext = () => {
  const context = useContext(ScreenPopContext);
  if (!context) {
    throw new Error('Tab components need to be wrapped by TabContext Provider');
  }
  return context;
};
