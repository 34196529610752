import { IDialerProps } from '../contexts/dialerContext';
import parsePhoneNumber from 'libphonenumber-js';

interface IDialerValidationProps {
  setOutboundProps(props: IDialerProps): void;
}

export function useDialerPropsValidation(props: IDialerValidationProps) {
  const noQueueNeeded = ['Phone Directory'];

  const paramsValid = ({ source, number, queueArn }: IDialerProps): boolean => {
    const queueValid = noQueueNeeded.includes(source) || !!queueArn;
    return phoneValid(number) && !!queueValid;
  };

  const phoneValid = (ani: string): boolean => {
    const phoneNumber = parsePhoneNumber(ani);

    return !!phoneNumber && phoneNumber.isValid();
  };

  return (_dialerProps: Omit<IDialerProps, 'isDisabled'>) => {
    const dialerProps = {
      ..._dialerProps,
      isDisabled: !paramsValid(_dialerProps as IDialerProps),
    };

    props.setOutboundProps(dialerProps);
  };
}
