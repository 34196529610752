import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  recordingButton: {
    position: 'relative',
  },
  status: {
    margin: '1em auto',
  },
  spinner: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    marginLeft: '-10px',
    marginTop: '-10px',
  },
}));
