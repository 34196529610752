import { ListItemAvatar, Tooltip, Avatar, makeStyles, ListItemIcon, ListItem } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import CallIcon from '@material-ui/icons/Call';
import ClearIcon from '@material-ui/icons/Clear';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PhonePausedIcon from '@material-ui/icons/PhonePaused';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import PeopleIcon from '@material-ui/icons/People';
import CallMadeIcon from '@material-ui/icons/CallMade';
import DetailsIcon from '@material-ui/icons/Details';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HistoryIcon from '@material-ui/icons/History';
import clsx from 'clsx';
import { CallContext } from '../../contexts/callContext';
import { getLogger } from '../../services/loggingService';
import { useContext } from 'react';
import CollapsibleCcpContext from './CollapsibleCcpContext';

const logger = getLogger('MenuButtons');

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  green: {
    backgroundColor: green[500],
  },
  red: {
    backgroundColor: red[500],
  },
}));

interface BaseProps {
  onClick: () => void;
}

interface IconMenuItemProps extends BaseProps {
  title: string;
  icon: JSX.Element;
}

export const IconMenuItem = (props: IconMenuItemProps) => {
  return (
    <ListItem button onClick={props.onClick}>
      <ListItemIcon>
        <Tooltip title={props.title} placement="right">
          {props.icon}
        </Tooltip>
      </ListItemIcon>
    </ListItem>
  );
};

interface AvatarMenuItemProps extends IconMenuItemProps {
  avatarColor: 'green' | 'red';
}
export const AvatarMenuItem = (props: AvatarMenuItemProps) => {
  const classes = useStyles();
  return (
    <ListItem button onClick={props.onClick}>
      <ListItemAvatar>
        <Tooltip title={props.title} placement="right">
          <Avatar className={clsx(classes.avatar, classes[props.avatarColor])}>{props.icon}</Avatar>
        </Tooltip>
      </ListItemAvatar>
    </ListItem>
  );
};

export const AnswerCallButton = () => {
  const { contact } = useContext(CallContext);

  return (
    <AvatarMenuItem
      onClick={() => contact?.accept()}
      title="Answer call"
      avatarColor="green"
      icon={<CallIcon style={{ color: 'white' }} />}
    />
  );
};

export const EndCallButton = () => {
  const { contact } = useContext(CallContext);
  return (
    <AvatarMenuItem
      onClick={() => contact?.getActiveInitialConnection()?.destroy()}
      title="End call"
      avatarColor="red"
      icon={<ClearIcon style={{ color: 'white' }} />}
    />
  );
};

interface ToggleButtonProps extends BaseProps {
  active: boolean;
}
export const MuteButton = (props: ToggleButtonProps) => {
  const { active, onClick } = props;

  if (active) {
    return (
      <AvatarMenuItem
        onClick={onClick}
        title="Unmute call"
        avatarColor="green"
        icon={<VolumeUpIcon style={{ color: 'white' }} />}
      />
    );
  } else {
    return (
      <AvatarMenuItem
        onClick={onClick}
        title="Mute call"
        avatarColor="red"
        icon={<VolumeOffIcon style={{ color: 'white' }} />}
      />
    );
  }
};

export const HoldButton = (props: ToggleButtonProps) => {
  const { active, onClick } = props;

  if (active) {
    return (
      <AvatarMenuItem
        onClick={onClick}
        title="Resume call"
        avatarColor="green"
        icon={<CallIcon style={{ color: 'white' }} />}
      />
    );
  } else {
    return (
      <AvatarMenuItem
        onClick={onClick}
        title="Place call on hold"
        avatarColor="red"
        icon={<PhonePausedIcon style={{ color: 'white' }} />}
      />
    );
  }
};

export const ExpandCcpButton = () => {
  const context = useContext(CollapsibleCcpContext);
  return (
    <IconMenuItem
      onClick={() => context.setSideMenuActiveIndex(context.showCallDetailsIndex())}
      title="Open CCP"
      icon={<CallIcon style={{ color: 'white' }} />}
    />
  );
};

export const PhoneDirectoryButton = () => {
  const context = useContext(CollapsibleCcpContext);

  return (
    context.showPhoneDirectoryIndex() >= 0 && (
      <IconMenuItem
        onClick={() => context.setSideMenuActiveIndex(context.showPhoneDirectoryIndex())}
        title="Open Directory"
        icon={<FolderSharedIcon style={{ color: 'white' }} />}
      />
    )
  );
};

export const QueueMetricsButton = () => {
  const context = useContext(CollapsibleCcpContext);
  return (
    context.showQueueMetricsIndex() >= 0 && (
      <IconMenuItem
        onClick={() => context.setSideMenuActiveIndex(context.showQueueMetricsIndex())}
        title="Custom Queue Outbound call"
        icon={<CallMadeIcon style={{ color: 'white' }} />}
      />
    )
  );
};

export const PeerStatusButton = () => {
  const context = useContext(CollapsibleCcpContext);
  return (
    context.showAgentLogIndex() >= 0 && (
      <IconMenuItem
        onClick={() => context.setSideMenuActiveIndex(context.showAgentLogIndex())}
        title="Open Peer Status"
        icon={<PeopleIcon style={{ color: 'white' }} />}
      />
    )
  );
};

export const MetricsButton = () => {
  const context = useContext(CollapsibleCcpContext);
  return (
    context.showCallLogIndex() >= 0 && (
      <IconMenuItem
        onClick={() => context.setSideMenuActiveIndex(context.showCallLogIndex())}
        title="Open Metrics"
        icon={<AssessmentIcon style={{ color: 'white' }} />}
      />
    )
  );
};

export const CallDetailsButton = () => {
  const context = useContext(CollapsibleCcpContext);
  return (
    context.showCallDetailsIndex() >= 0 && (
      <IconMenuItem
        onClick={() => context.setSideMenuActiveIndex(context.showCallDetailsIndex())}
        title="Open Call Details"
        icon={<DetailsIcon style={{ color: 'white' }} />}
      />
    )
  );
};

export const CallHistoryButton = () => {
  const context = useContext(CollapsibleCcpContext);
  return (
    context.showCallHistoryIndex() >= 0 && (
      <IconMenuItem
        onClick={() => context.setSideMenuActiveIndex(context.showCallHistoryIndex())}
        title="Open History"
        icon={<HistoryIcon style={{ color: 'white' }} />}
      />
    )
  );
};
