import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { useState } from 'react';

type TipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export function AppToolTip(props: { text: string; placement?: TipPlacement }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={props.text}
        placement={props.placement}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener>
        <IconButton onClick={handleTooltipOpen}>
          <InfoIcon color={'primary'} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}
