import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState, useCallback } from 'react';
import { useDialerPropsValidation } from '../hooks/useDialogPropsValidation';

export interface IDialerProps {
  source: string;
  isDisabled: boolean;
  number: string;
  immediateDial: boolean;
  queueArn?: string;
  clearCall?: () => void;
}
export interface IDialerContext {
  dialerProps: IDialerProps;
  setDialerProps: (props: Omit<IDialerProps, 'isDisabled'>) => void;
}

export const DialerContext = createContext<IDialerContext>({
  dialerProps: {
    source: 'DialerContext',
    isDisabled: false,
    number: '',
    immediateDial: false,
    queueArn: undefined,
    clearCall: () => {},
  },
  setDialerProps: (_dialerProps: Omit<IDialerProps, 'isDisabled'>): void => {},
});

const DialerContextProvider: FC<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {
  const [outboundProps, setOutboundProps] = useState<IDialerProps>({
    source: 'DialerContext',
    isDisabled: true,
    number: '',
    immediateDial: false,
    queueArn: undefined,
    clearCall: () => {},
  });

  const setDialerProps = useDialerPropsValidation({ setOutboundProps });

  const returnDialerContext = useMemo<IDialerContext>(
    () => ({
      dialerProps: outboundProps,
      setDialerProps,
    }),
    [outboundProps, setDialerProps]
  );

  return <DialerContext.Provider value={returnDialerContext}>{props.children}</DialerContext.Provider>;
};

export const useDialerContextProvider = () => {
  const context = useContext(DialerContext);
  if (!context) {
    throw new Error('Cannot use DialerContext without Provider');
  }
  return context;
};

DialerContext.displayName = 'DialerContext'; // used in dev tools
export { DialerContextProvider };
