import { Button, makeStyles } from '@material-ui/core';
import { useContext, useState } from 'react';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext';
import { getLogger } from '../../services/loggingService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  logoutButton: {
    width: '200px',
  },
  confirmDialog: {
    width: '300px',
  },
}));

const logger = getLogger('LogoutButton');

interface LogoutButtonProps {
  disabled: boolean;
}
export const LogoutButton = (props: LogoutButtonProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { agent } = useContext(CallContext);
  const {
    config: { connectUrl },
  } = useContext(AppContext);

  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };

  const handleSignOut = () => {
    // Set agent state to Offline if state is available.
    var state = agent?.getAgentStates().filter(s => s.name === 'Offline')[0];
    if (state) {
      agent?.setState(state, {
        success: () => {
          close();
          logout();
        },
        failure: () => logger.debug('Could not sign Agent out'),
      });
    } else {
      close();
      logout();
    }
  };

  const logout = () => {
    setTimeout(() => {
      window.close();
    }, 500);
    window.location.href = `${connectUrl}/logout`;
  };

  if (!agent) {
    return null;
  }

  return (
    <>
      <Button
        disabled={props.disabled}
        variant="contained"
        color="primary"
        size="large"
        className={classes.logoutButton}
        endIcon={<ExitToAppIcon />}
        onClick={open}>
        logout
      </Button>
      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle className={classes.confirmDialog} id="alert-dialog-title">
          {'Signing out?'}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            cancel
          </Button>
          <Button onClick={handleSignOut} color="primary">
            sign out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
