import Alert from '@material-ui/lab/Alert';
import 'amazon-connect-streams';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Dropdown } from 'semantic-ui-react';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext';
import { useDataContextProvider } from '../../contexts/dataContext';
import RestApiContext from '../../contexts/restApiContext';
import { getLogger } from '../../services/loggingService';

interface IState {
  contact?: connect.Contact;
  disposition: string;
  loading: boolean;
  buttonState: {
    text: string;
  };
  submittingDisposition: boolean;
  dispositionSubmitted: boolean;
  submittedDisposition: string;
}

const logger = getLogger('Disposition');
const defaultState = (): IState => {
  return {
    contact: undefined,
    disposition: '',
    loading: false,
    buttonState: {
      text: 'Submit Disposition',
    },
    submittingDisposition: false,
    dispositionSubmitted: false,
    submittedDisposition: '',
  };
};

/**
 * This panel will be displayed below the CCP iFrame
 */
export function Disposition() {
  const { config, setAlert } = useContext(AppContext);
  const api = useContext(RestApiContext);
  const { currentClientIdentifier, currentQueueName, onACW, contactId } = useContext(CallContext);
  const { sendWebsocketData } = useDataContextProvider();
  const [state, setState] = useState(defaultState());
  const [availableDispositions, setAvailableDispositions] = useState<string[]>([]);
  const [showDispositions, setShowDispositions] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (onACW) {
        await fetchDispositions();
        setShowDispositions(true);
      } else {
        setShowDispositions(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onACW]);

  // based on the dispositionSource configured
  // get dispositions as string[] from the options listed in the config file (queue-based lists)
  // or the multi-tenant config file that lives on S3
  const fetchDispositions = useCallback(async () => {
    console.log('Fetching New Dispositions');
    if (!currentClientIdentifier) {
      setAvailableDispositions([]);
    } else if (config.dispositionFileKey === '') {
      config.disposition.options && config.disposition.options[currentQueueName]
        ? setAvailableDispositions(config.disposition.options[currentQueueName])
        : setAvailableDispositions([]);
    } else {
      try {
        setAvailableDispositions(await api.getTenantDispositionsByQueue(currentClientIdentifier, currentQueueName));
      } catch (error) {
        logger.error(`Cannot get dispositions for Client:${currentClientIdentifier} and Queue: ${currentQueueName}`);
        logger.errorObj(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueueName, currentClientIdentifier]);

  const submitDisposition = async () => {
    logger.debug('submitDisposition clicked');

    try {
      setState({
        ...state,
        submittingDisposition: true,
        buttonState: {
          ...state.buttonState,
          text: 'Submitting ...',
        },
      });

      const attributes = {
        disposition: state.disposition,
      };

      logger.debug('Update contact attribute query ', contactId, attributes);
      await api.updateContactAttributes(contactId, attributes);
      // moving away from the api - send the data through the socket to DDB
      logger.debug('Disposition Selected:: Send to websocket');
      sendDispositionWebsocketData(contactId, state.disposition);

      setAlert('Success', 'success');
    } catch (error) {
      setAlert(error.message, 'error');
    } finally {
      setState({
        ...state,
        submittingDisposition: false,
        submittedDisposition: state.disposition,
        disposition: '',
        dispositionSubmitted: true,
        buttonState: {
          ...state.buttonState,
          text: 'Resubmit Disposition',
        },
      });
    }
  };

  const sendDispositionWebsocketData = (contactId: string, disposition: string): void => {
    const payload = {
      action: 'setDisposition',
      ContactId: contactId,
      DispositionValue: disposition,
    };
    try {
      sendWebsocketData(payload);
    } catch (error) {
      logger.error('Could not send to websocket: disposition for contactID: ' + contactId);
      logger.error(error.message);
    }
  };

  const handleChange = (event: any, data: any): void => {
    logger.debug('Disposition changed: ', data);
    setState({
      ...state,
      disposition: data.value,
    });
  };

  const getDispositionOptions = (options: string[]) => {
    return options.map(option => ({
      key: option,
      text: option,
      value: option,
    }));
  };

  const requiredMessage =
    !state.disposition && !state.dispositionSubmitted ? (
      <Alert severity={'info'}>Disposition is required.</Alert>
    ) : null;
  const submittedMessage = state.dispositionSubmitted ? (
    <Alert severity={'success'}>{`${state.submittedDisposition} submitted.`}</Alert>
  ) : null;

  return (
    <>
      {showDispositions ? (
        <Card id="disposition-list" className="card-jmb">
          <Card.Content>
            <Card.Header className="mb-5" textAlign="left">
              Disposition List
            </Card.Header>

            <Dropdown
              className="mb-5"
              clearable
              search
              selection
              fluid
              onChange={handleChange}
              value={state.disposition}
              options={getDispositionOptions(availableDispositions)}
              placeholder="Select a disposition"
            />

            {requiredMessage}
            {submittedMessage}
            {state.disposition ? (
              <Button
                disabled={state.submittingDisposition}
                loading={state.submittingDisposition}
                onClick={submitDisposition}
                className="action-button">
                {state.buttonState.text}
              </Button>
            ) : null}
          </Card.Content>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}
