import { makeStyles, Theme } from '@material-ui/core/styles';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext'; // move to local state
import { DISPLAY_SOURCE } from '../../contexts/screenPopContext/screenPopContext';
const OuterTabs = lazy(() => import('./outerTabs'));
const ScreenPopContextProvider = lazy(() => import('../../contexts/screenPopContext/screenPopContextProvider'));

// ways to define tabs:
//  disabled: show default page
//  single URL (static config): iframe
//  single URL (static config): iframe
//  single URL (specify attribute name): iframe
//  single URL (specify attribute name): iframe
//  multi-tab from config file

interface IProps {
  drawerOpen: boolean;
}

const contactTabHeight = '42px';
const useStyles = makeStyles((theme: Theme) => ({
  splashContainer: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '600px',
  },
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    // height: "600",
  },
  rootOpen: {
    position: 'absolute',
    top: '64px',
    left: '350px',
    right: '0',
    bottom: '0',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rootClosed: {
    position: 'absolute',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    top: '64px',
    left: '54px',
    right: '0',
    bottom: '0',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contactAppBar: {
    backgroundColor: '#257CAF',
    height: contactTabHeight,
  },
  tabsRoot: {
    minHeight: contactTabHeight,
    height: contactTabHeight,
  },
  tabRoot: {
    minHeight: contactTabHeight,
    height: contactTabHeight,
  },
  screenPopAppBar: {
    backgroundColor: '#257CAF',
  },
  miniBarStyles: {
    backgroundColor: '#4A4A4A',
  },
  callEndedMajorBarColor: {
    backgroundColor: '#666666',
  },
  majorBarColor: {
    backgroundColor: '#257CAF',
  },
  backgroundDarkGrey: {
    backgroundColor: '',
  },
  screenTabPanel: {
    position: 'absolute',
    top: 42 + 48 + 'px',
    bottom: 0,
    right: 0,
    left: 0,
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '5px',
    paddingLeft: '5px',
    backgroundColor: theme.palette.background.paper,
  },
  hiddenScreenPop: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '1px',
    height: '1px',
  },
}));

type PopType = 'disabled' | 'popup' | 'single' | 'tabs';

export const getDefaultSplashScreen = (classes: any) => {
  return (
    <div className={classes.splashContainer}>
      <div className={classes.logo}>
        <Image src="top-logo.svg" />
      </div>
    </div>
  );
};

export default function ScreenPopContainer({ drawerOpen }: IProps) {
  const classes = useStyles();

  const { config, voicemailOpen } = useContext(AppContext);

  const getPopType = (): PopType => {
    if (!config.screenPop.enabled) {
      return 'disabled';
    }
    if (config.screenPop.displaySource === DISPLAY_SOURCE.TAB_CONFIG) {
      return 'tabs';
    }
    if (config.screenPop.displaySource === DISPLAY_SOURCE.SINGLE_URL) {
      if (config.screenPop.displayConfig.urlConfig?.useIframe) {
        return 'single';
      } else {
        return 'popup';
      }
    }
    return 'disabled';
  };

  const [popType] = useState(getPopType());
  const [url, setUrl] = useState('');

  const { contact } = useContext(CallContext);

  useEffect(() => {
    if (contact) {
      const urlConfig = config.screenPop.displayConfig.urlConfig;
      if (urlConfig && (popType === 'single' || popType === 'popup')) {
        let popUrl = urlConfig.staticUrl;
        if (urlConfig.useAttribute) {
          popUrl = contact?.getAttributes()[urlConfig.connectAttribute]?.value as string;
        }

        if (popType === 'popup') {
          console.log('opening popup', { url: popUrl });
          window.open(popUrl, 'ccp-screen-pop');
        } else {
          setUrl(popUrl);
        }
      }
    } else {
      setUrl('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const renderIframe = (url: string) => {
    return (
      <div className={classes.splashContainer}>
        <iframe className="screenpop-frame" seamless src={url} width="100%" height="100%">
          <p>Your browser does not allow iframes</p>
        </iframe>
      </div>
    );
  };

  const renderContent = () => {
    if (voicemailOpen) {
      return renderIframe(config.voicemailUrl);
    }

    const logoPlaceholder = getDefaultSplashScreen(classes);

    if (popType === 'tabs') {
      return (
        <Suspense fallback={logoPlaceholder}>
          <ScreenPopContextProvider>
            <OuterTabs classes={classes} />
          </ScreenPopContextProvider>
        </Suspense>
      );
    }

    if (popType === 'single' && url) {
      return renderIframe(url);
    }
    return logoPlaceholder;
  };

  return <div className={drawerOpen ? classes.rootOpen : classes.rootClosed}>{renderContent()}</div>;
}
