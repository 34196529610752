import Button, { ButtonProps } from '@material-ui/core/Button';
import { AttachMoney, MoneyOff } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext';
import { CallType } from '../../models/screenPopConfig';

const PaylineTransfer = (): JSX.Element => {
  const [payLineTabActive, setPayLineTabActive] = useState(false);
  const { availableQueueEndpoints } = useContext(CallContext);
  const { config, togglePaymentTab } = useContext(AppContext);
  const { agent, contact } = useContext(CallContext);

  const { paylineQuickConnect } = config;

  useEffect(() => {
    if (contact && getPaylineEndpoint()) {
      const connectionType = contact.getActiveInitialConnection()?.getType().toUpperCase() as CallType;
      togglePaymentTab(connectionType, payLineTabActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payLineTabActive, contact]);

  useEffect(() => {
    const [c] = agent.getContacts();

    if (!c || !getPaylineEndpoint()) {
      return;
    }

    c.onRefresh(() => {
      setPayLineTabActive(hasPaylineConnection());
    });

    setPayLineTabActive(hasPaylineConnection());

    function hasPaylineConnection() {
      const thirdPartyConnection = c.getSingleActiveThirdPartyConnection();

      return (
        !!thirdPartyConnection && (thirdPartyConnection as any)._getData().quickConnectName === paylineQuickConnect
      );
    }
  });

  const transferToPayment = async () => {
    if (!agent || !paylineQuickConnect) {
      return;
    }

    const paymentTransferEndPoint = getPaylineEndpoint();

    const agentContacts = agent.getContacts(connect.ContactType.VOICE);

    if (agentContacts.length === 0) {
      return;
    } else if (agentContacts.length === 1) {
      agentContacts[0].addConnection(paymentTransferEndPoint);
    } else {
      throw new Error('Already have 2 active voice contacts');
    }
  };

  const closePayment = async () => {
    if (!agent) {
      return;
    }

    const [agentContact] = agent.getContacts(connect.ContactType.VOICE);

    agentContact.getSingleActiveThirdPartyConnection()?.destroy();
  };

  const getPaylineEndpoint = () => availableQueueEndpoints.find(({ name }) => name === paylineQuickConnect);

  const buttonProps: ButtonProps = {
    variant: 'contained',
    color: 'primary',
    size: 'large',
    fullWidth: true,
  };

  const finishPaymentButtonProps = {
    ...buttonProps,
    onClick: closePayment,
    startIcon: <MoneyOff />,
  };

  const startPaymentProps: ButtonProps = {
    ...buttonProps,
    onClick: transferToPayment,
    startIcon: <AttachMoney />,
  };

  if (!contact || contact.getStatus().type !== 'connected') {
    return <p>No call in progress.</p>;
  }

  if (!getPaylineEndpoint()) {
    return <p>Payments not enabled for this call.</p>;
  }

  if (payLineTabActive) {
    return (
      <div style={{ margin: '1em' }}>
        <Button {...finishPaymentButtonProps}>Close Payment</Button>
      </div>
    );
  }

  return (
    <div style={{ margin: '1em' }}>
      <Button {...startPaymentProps}>Take Payment</Button>
    </div>
  );
};

export default PaylineTransfer;
