import React from 'react';
import { Table } from 'semantic-ui-react';
import { ICall } from '../../models/websocketInterfaces';

interface IProps {
  callLog: ICall[];
}

const CallLog = ({ callLog }: IProps): JSX.Element => {
  function renderRows(callLog: ICall[]): JSX.Element[] {
    return callLog.map((call: ICall, i: number) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>{call.Phonenumber}</Table.Cell>
          <Table.Cell>{new Date(call.ConnectedTimestamp).toLocaleTimeString()}</Table.Cell>
          {/* Duration is in seconds */}
          <Table.Cell>{new Date(1000 * parseInt(call.Duration)).toISOString().substr(11, 8)}</Table.Cell>
          <Table.Cell>{call.Type}</Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Phone</Table.HeaderCell>
          <Table.HeaderCell>Start</Table.HeaderCell>
          <Table.HeaderCell>Duration</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderRows(callLog)}</Table.Body>
    </Table>
  );
};

export default CallLog;
