import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext';
import { ICallDetailsAttribute } from '../../models/callDetailsConfig';

export function CallInfo() {
  const [state, setState] = useState<ICallDetailsAttribute[]>([]);

  const { config } = useContext(AppContext);
  const { contact } = useContext(CallContext);

  useEffect(() => {
    if (!contact) {
      setState([]);
      return;
    }

    contact.onRefresh(() => {
      // console.log("Refresh CallInfo");
      refreshData(contact);
    });

    contact.onDestroy(() => {
      setState([]);
    });
  }, [contact]);

  // Load attributes here
  const refreshData = (contact: connect.Contact) => {
    const attributes = contact.getAttributes();
    const attrList = config.callDetails.visibleAttributes.map(attr => {
      return {
        ...attr,
        value: attributes[attr.attributeKey]?.value || 'Not Set',
      };
    });
    setState(attrList);
  };

  if (!contact) {
    return <p>No Contact Available</p>;
  }

  const attrList = state
    .filter(attr => {
      return attr.required || (attr.value && attr.value !== 'Not Set');
    })
    .map((attr, i) => (
      <ListItem key={`${attr.attributeKey}_${i}`}>
        <ListItemText primary={attr.label} secondary={attr.value} />
      </ListItem>
    ));

  if (attrList.length < 1) {
    return <p>No Contact Details</p>;
  }
  // Style Attribute Card Here
  return (
    <List dense disablePadding>
      {attrList}
    </List>
  );
}
