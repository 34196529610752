import { getLogger } from './loggingService';
import { ICallDetailsConfig } from '../models/callDetailsConfig';
import { IDispositionConfig } from '../models/dispositionConfig';
import { CallType, IScreenPopConfig, ITabConfig } from '../models/screenPopConfig';
import { IConfig } from '../models/config';
import { Loggers } from '../models/loggers';

const logger = getLogger(Loggers.ConfigurationService);
export default class ConfigurationService implements IConfig {
  private cfg: IConfig;
  public paylineIframeLabel = 'Payments';

  public async load() {
    if (!this.cfg) {
      const response = await fetch('config.json');
      this.cfg = (await response.json()) as IConfig;
      logger.debug('Config Loaded');
    }
  }
  get environment(): string {
    return this.cfg.environment;
  }

  get ccpUrl(): string {
    return this.cfg.ccpUrl;
  }

  get connectUrl(): string {
    const parts = this.ccpUrl.split('/');
    const hostname = parts[2];

    if (hostname.includes('.awsapps.com')) {
      return `https://${hostname}/connect`;
    } else {
      return `https://${hostname}`;
    }
  }

  get region(): string {
    return this.cfg.region;
  }

  get samlSsoUrl(): string {
    return this.cfg.samlSsoUrl;
  }

  get screenPop(): IScreenPopConfig {
    return (
      this.cfg.screenPop ||
      ({
        enabled: false,
      } as any)
    );
  }

  get tokenAuthNumber(): string {
    return this.cfg.tokenAuthNumber;
  }

  get apiUrl(): string {
    return this.cfg.apiUrl;
  }

  get wssUrl(): string {
    return this.cfg.wssUrl;
  }

  get recordingControls(): string {
    return this.cfg.recordingControls;
  }

  get phoneDirectoryDataKey(): string {
    return this.cfg.phoneDirectoryDataKey;
  }
  get dispositionEnabled(): string {
    return this.cfg.dispositionEnabled;
  }
  get dispositionFileKey(): string {
    return this.cfg.dispositionFileKey;
  }
  get useQueueMetrics(): string {
    return this.cfg.useQueueMetrics;
  }
  get ctrStreamName(): string {
    return this.cfg.ctrStreamName;
  }
  get atrStreamName(): string {
    return this.cfg.atrStreamName;
  }
  get voicemailUrl(): string {
    return this.cfg.voicemailUrl;
  }

  get enableAudioDeviceSettings(): string {
    return this.cfg.enableAudioDeviceSettings || 'false';
  }

  get enablePhoneTypeSettings(): string {
    return this.cfg.enablePhoneTypeSettings || 'true';
  }

  get disposition(): IDispositionConfig {
    const notSet = {
      enabled: false,
      required: false,
      attributeName: '',
      defaultDisposition: '',
      dispositionSource: 'options',
      options: {},
    };

    const enabled = this.cfg.dispositionEnabled === 'true' && this.cfg.disposition;
    return enabled ? this.cfg.disposition : notSet;
  }

  get callDetails(): ICallDetailsConfig {
    const notSet = {
      visibleAttributes: [],
    };
    return this.cfg.callDetails || notSet;
  }

  get paylineQuickConnect(): string {
    return this.cfg.paylineQuickConnect || null;
  }

  get isPaylineSSO(): boolean {
    return this.cfg.paylineSSO === 'true';
  }

  isLocal(): boolean {
    return this.environment === 'local';
  }

  isV2(): boolean {
    return this.ccpUrl.includes('ccp-v2');
  }

  enablePaymentIframe(currentCallType: Omit<CallType, 'Chat'>) {
    const path = this.isPaylineSSO ? 'saml/' : '';
    const url = `${this.cfg.paylineDomain}${path}`;

    const hiddenPaymentTab: ITabConfig = {
      label: this.paylineIframeLabel,
      url,
      defaultUrlTemplate: '{{domain}}?{{payline}}',
      isHidden: false,
    };
    const callConfig = this.cfg.screenPop.displayConfig.tabConfig.find(({ callType }) => callType === currentCallType);

    const { tabs } = callConfig || { tabs: [] };
    const paymentTabIndex = tabs.findIndex(({ label }) => this.paylineIframeLabel === label);

    paymentTabIndex >= 0 ? tabs.splice(paymentTabIndex, 1, hiddenPaymentTab) : tabs.unshift(hiddenPaymentTab);
  }

  disabledPaymentIframe(currentCallType: Omit<CallType, 'Chat'>) {
    const path = this.isPaylineSSO ? 'saml/' : '';
    const url = `${this.cfg.paylineDomain}${path}end`;

    const hiddenPaymentTab: ITabConfig = {
      label: this.paylineIframeLabel,
      url,
      defaultUrlTemplate: '{{domain}}?{{payline}}',
      isHidden: true,
    };
    const callConfig = this.cfg.screenPop.displayConfig.tabConfig.find(({ callType }) => callType === currentCallType);

    const { tabs } = callConfig || { tabs: [] };
    const paymentTabIndex = tabs.findIndex(({ label }) => this.paylineIframeLabel === label);

    paymentTabIndex >= 0 ? tabs.splice(paymentTabIndex, 1, hiddenPaymentTab) : tabs.unshift(hiddenPaymentTab);
  }

  protected set(testConfig: IConfig) {
    logger.debug('CONFIG OVERRIDE');
    logger.debug(JSON.stringify(testConfig));
    this.cfg = testConfig;
  }
}
