import { createTheme, ThemeProvider } from '@material-ui/core';
import React from 'react';
import './app.css';
import CollapsibleCcp from './components/CollapsibleCcp/CollapsibleCcp';
import { AppContextProvider } from './contexts/appContext';
import { CallContextProvider } from './contexts/callContextProvider';
import { RestApiContextProvider } from './contexts/restApiContext';
import { green } from '@material-ui/core/colors';
import { TokenContextProvider } from './contexts/tokenContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4786A9',
    },
    secondary: {
      main: green[500],
    },
  },
});

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <CallContextProvider>
          <TokenContextProvider>
            <RestApiContextProvider>
              <CollapsibleCcp />
            </RestApiContextProvider>
          </TokenContextProvider>
        </CallContextProvider>
      </AppContextProvider>
    </ThemeProvider>
  );
}
