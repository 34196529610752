import React, { useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext';
import { IRecentCall } from '../../models/recentCall';
import { getLogger } from '../../services/loggingService';
import { useCallLogState } from '../../hooks/useCallLogState';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';

const logger = getLogger('RecentCalls');

export function RecentCalls() {
  const { calls, setCalls } = useCallLogState();

  const { setAlert } = useContext(AppContext);
  const { contact } = useContext(CallContext);

  const onConnected = (c: connect.Contact) => {
    try {
      const exists = calls.current.find(x => x.contactId === c.getContactId());

      if (exists) {
        return;
      }

      const currentCall: IRecentCall = {
        contactId: c.getContactId(),
        phoneNumber: c.getInitialConnection()?.getEndpoint().phoneNumber,
        disposition: c.getAttributes().disposition?.value || '',
        customerName: c.getAttributes().customerName?.value || '',
        brandCode: c.getAttributes().brandCode?.value || ('' as any),
        startTimestamp: new Date().getTime(),
      };
      setCalls([currentCall, ...calls.current]);
    } catch (error) {
      logger.error(error);
    }
  };

  const onRefresh = (c: connect.Contact) => {
    if (!calls || calls.current.length === 0) {
      return;
    }

    const disposition = c.getAttributes().disposition?.value || '';
    const exists = calls.current.find(x => x.contactId === c.getContactId());
    if (exists && exists.disposition !== disposition) {
      exists.disposition = disposition;
      setCalls([...calls.current]);
    }
  };

  const onEnded = (c: connect.Contact) => {
    if (!calls || calls.current.length === 0) {
      return;
    }

    const exists = calls.current.find(x => x.contactId === c.getContactId());
    if (exists && !exists.endTimestamp) {
      exists.endTimestamp = new Date().getTime();
      exists.disposition = c.getAttributes().disposition?.value || '';
      setCalls([...calls.current]);
    }
  };

  useEffect(() => {
    if (!contact) return;
    contact.onConnected(onConnected);
    contact.onRefresh(onRefresh);
    contact.onEnded(onEnded);
  }, [contact]);

  const clickToCall = (phoneNumber: string) => {
    const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
    connect.agent((agent: connect.Agent) => {
      agent.connect(endpoint, {
        success: () => {
          // Do nothing
        },
        failure: e => {
          logger.error('RecentCalls.ClickToCall:Error', e);
          setAlert('Call Failed', 'error');
        },
      });
    });
  };

  const millisecondsToHuman = (ms: number) => {
    let seconds: any = Math.floor((ms / 1000) % 60);
    let minutes: any = Math.floor((ms / 1000 / 60) % 60);
    let hours: any = Math.floor((ms / 1000 / 3600) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds;
  };

  const renderCall = (call: IRecentCall) => {
    return (
      <ListItem key={call.contactId}>
        <ListItemAvatar>
          <Avatar>
            <IconButton onClick={() => clickToCall(call.phoneNumber)}>
              <PhoneIcon fontSize="small" />
            </IconButton>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={call.phoneNumber}
          secondary={
            <React.Fragment>
              {dayjs(call.startTimestamp).format('MMM DD - hh:mm A')} &nbsp; &nbsp;
              {millisecondsToHuman((call.endTimestamp || call.startTimestamp) - call.startTimestamp)}
              {call.disposition && (
                <span>
                  <br />
                  <b>Disposition:</b> {call.disposition ? call.disposition : ''}
                </span>
              )}
            </React.Fragment>
          }></ListItemText>
      </ListItem>
    );
  };

  if (calls.current.length === 0) {
    return <p>No recent calls to display.</p>;
  }

  return (
    <List dense disablePadding>
      {calls.current.map(x => renderCall(x))}
    </List>
  );
}
