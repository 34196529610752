import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { Alert } from '@material-ui/lab';
import { CcpAlert } from '../../models/ccpAlert';

interface IProps {
  alert: CcpAlert;
  clear: () => void;
}

/**
 * Add SnackBar Support at the AppContextProvider level
 */
export function CcpSnackbar(props: IProps) {
  if (!props.alert || !props.alert.message) {
    return null;
  }
  if (props.alert.timeout) {
    setTimeout(props.clear, props.alert.timeout);
  }
  return (
    <Snackbar
      open={props.alert.message?.length > 0}
      onClose={() => props.clear()}
      TransitionComponent={Slide}
      autoHideDuration={6000}>
      <Alert onClose={() => props.clear()} severity={props.alert.type}>
        {props.alert.message}
      </Alert>
    </Snackbar>
  );
}
