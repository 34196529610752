import { useRef, useState } from 'react';
import { IRecentCall } from '../models/recentCall';

/**
 * Uses a ref to track call log data because it must be manipulated in async methods.
 * https://css-tricks.com/dealing-with-stale-props-and-states-in-reacts-functional-components/
 */
export function useCallLogState() {
  const initial = JSON.parse(localStorage.getItem('calls') || '[]');

  const ref = useRef<IRecentCall[]>(initial);
  const [, forceRender] = useState(false);

  function setCalls(newState: IRecentCall[]) {
    if (newState.length > 5) {
      newState.length = 5;
    }
    localStorage.setItem('calls', JSON.stringify(newState));
    ref.current = newState;
    forceRender(s => !s);
  }

  return {
    calls: ref,
    setCalls,
  };
}
