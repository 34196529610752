import { createTheme, makeStyles, Theme, ThemeProvider } from '@material-ui/core';
import { blue, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useContext, useEffect, useState } from 'react';
import { CallContext } from '../../contexts/callContext';
import { IQueue } from '../../models/websocketInterfaces';

interface IProps {
  queues: IQueue[];
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  py5: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  ml5: {
    marginLeft: '5px',
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  primaryListItemText: {
    fontSize: '0.7em', //Insert your required size
  },
  secondaryListItemText: {
    fontSize: '0.6em', //Insert your required size
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[500],
    },
  },
});
interface IAvailableQueueEPDict {
  [name: string]: connect.Endpoint;
}
const QueueMetrics = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const [availableQueueEPDict, setAvailableQueueEPDict] = useState<IAvailableQueueEPDict>({});
  const { availableQueueEndpoints, currentQueueName, agent } = useContext(CallContext);

  useEffect(() => {
    if (availableQueueEndpoints.length === 0) {
      setAvailableQueueEPDict({});
    } else {
      const newDict = availableQueueEndpoints.reduce((dict: IAvailableQueueEPDict, ep: connect.Endpoint) => {
        const key: string = ep.name;
        dict[key] = ep;
        return dict;
      }, {});
      setAvailableQueueEPDict(newDict);
    }
  }, [availableQueueEndpoints]);

  const onCallFailure = (err: any): void => {
    err = JSON.parse(err);
    console.log(err);
  };
  const onCallSuccess = (): void => {
    console.log('Successful Transfer');
  };

  const transferToQueueEndpoint = async (qcEp: connect.Endpoint) => {
    if (!agent) return;
    const agentContacts = agent.getContacts(connect.ContactType.VOICE);
    if (agentContacts.length === 0) {
      return;
    } else if (agentContacts.length === 1) {
      agentContacts[0].addConnection(qcEp, {
        success: onCallSuccess,
        failure: onCallFailure,
      });
    } else {
      throw new Error('Already have 2 active voice contacts');
    }
  };

  const availableQuickConnectForQueue = (queueName: string) =>
    Object.prototype.hasOwnProperty.call(availableQueueEPDict, queueName);

  function renderRows(queues: IQueue[]): JSX.Element[] {
    return queues.map((queue: IQueue, i: number) => {
      return (
        <ListItem key={'queue-' + i} dense>
          <ListItemText
            classes={{
              primary: classes.primaryListItemText,
              secondary: classes.secondaryListItemText,
            }}
            primary={queue.Queue}
            secondary={`Waiting: ${queue.ContactsQueued} | ${new Date(parseInt(queue.WaitTime))
              .toISOString()
              .substr(11, 8)}`}
          />
          {availableQuickConnectForQueue(queue.Queue) && currentQueueName !== queue.Queue && (
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => transferToQueueEndpoint(availableQueueEPDict[queue.Queue])}
                edge="end"
                aria-label="comments">
                <ArrowForwardIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      );
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <List dense key="queue-metrics">
          {renderRows(props.queues)}
        </List>
      </div>
    </ThemeProvider>
  );
};

export default QueueMetrics;
