import { Button, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import AppContext from '../../contexts/appContext';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import { CallContext } from '../../contexts/callContext';

const useStyles = makeStyles(theme => ({
  voicemailButton: {
    width: '200px',
  },
  confirmDialog: {
    width: '300px',
  },
}));

export const VoicemailButton = () => {
  const classes = useStyles();
  const { config, voicemailOpen, setVoicemailOpen } = useContext(AppContext);
  const { agent } = useContext(CallContext);

  const handleVoicemail = () => {
    console.log('checking voicemail...');
    setVoicemailOpen(!voicemailOpen);
  };

  if (!agent || !config.voicemailUrl) {
    return null;
  }

  return (
    <Button
      variant={voicemailOpen ? 'outlined' : 'contained'}
      color="primary"
      size="large"
      className={classes.voicemailButton}
      endIcon={<VoicemailIcon />}
      onClick={handleVoicemail}>
      voicemail
    </Button>
  );
};
