import { createRef, RefObject, useContext, useEffect } from 'react';
import 'amazon-connect-streams';
import AppContext from '../contexts/appContext';
import { CallContext } from '../contexts/callContext';
import { Box } from '@material-ui/core';

/**
 * This is the simplest CCP component. Extend as needed.
 */

const ccpRef: RefObject<HTMLDivElement> = createRef();

interface CcpProps {
  collapsed?: boolean;
}
export function Ccp(props: CcpProps) {
  const { config } = useContext(AppContext);
  const { initialize } = useContext(CallContext);

  useEffect(() => {
    initialize(ccpRef.current);

    // Un-mount action
    return () => connect.core.terminate();
  }, []);

  return (
    <Box id="ccpFrame" className={config.isV2() ? 'ccp-wrapper v2' : 'ccp-wrapper'}>
      <div className={props.collapsed ? 'ccp-frame collapsed' : 'ccp-frame'} ref={ccpRef} />
    </Box>
  );
}
