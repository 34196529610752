import React, { useContext, useState } from 'react';
import AppContext from '../../contexts/appContext';

export enum DISPLAY_INDICES {
  NOT_VISIBLE = -1,
  RECORDING_CONTROLS = 0,
  CALL_DETAILS_INDEX = 1,
  PHONE_DIRECTORY_INDEX = 2,
  MANUAL_OUTBOUND_CALL_INDEX = 3,
  AGENT_LOG_TRANSFER_INDEX = 4,
  QUEUE_METRICS_INDEX = 5,
  CALL_HISTORY_INDEX = 6,
  CALL_LOG_INDEX = 7,
  PAYLINE_TRANSFER = 8,
}
export interface ICollapsibleCcpContext {
  open: boolean;
  setOpen: (next: boolean) => void;
  sideMenuActiveIndex: number;
  setSideMenuActiveIndex: (index: number) => void;

  showRecordingControlsIndex: () => number;
  showCallDetailsIndex: () => number;
  showPhoneDirectoryIndex: () => number;
  showQueueMetricsIndex: () => number;
  showCallLogIndex: () => number;
  showAgentLogIndex: () => number;
  showCallHistoryIndex: () => number;
  showManualOutboundCallIndex: () => number;
  showPaylineTransferIndex: () => number;
}

export const CollapsibleCcpContext = React.createContext<ICollapsibleCcpContext>(null);

export function CollapsibleCcpContextProvider(props: { children: any }) {
  const [open, setOpen] = useState(true);
  const [sideMenuActiveIndex, setSideMenuActiveIndex] = useState(0);

  const { config } = useContext(AppContext);

  const ctx: ICollapsibleCcpContext = {
    open,
    setOpen,

    sideMenuActiveIndex,
    setSideMenuActiveIndex: index => {
      setSideMenuActiveIndex(index);
      setOpen(true);
    },

    showRecordingControlsIndex: (): number => {
      return config.recordingControls === 'true' ? DISPLAY_INDICES.RECORDING_CONTROLS : DISPLAY_INDICES.NOT_VISIBLE;
    },

    showCallDetailsIndex: (): number => {
      return config.callDetails.visibleAttributes.length > 0
        ? DISPLAY_INDICES.CALL_DETAILS_INDEX
        : DISPLAY_INDICES.NOT_VISIBLE;
    },
    showPhoneDirectoryIndex: (): number => {
      return config.phoneDirectoryDataKey !== '' ? DISPLAY_INDICES.PHONE_DIRECTORY_INDEX : DISPLAY_INDICES.NOT_VISIBLE;
    },
    showQueueMetricsIndex: (): number => {
      return config.useQueueMetrics === 'true' ? DISPLAY_INDICES.QUEUE_METRICS_INDEX : DISPLAY_INDICES.NOT_VISIBLE;
    },
    showCallLogIndex: (): number => {
      return config.ctrStreamName !== '' ? DISPLAY_INDICES.CALL_LOG_INDEX : DISPLAY_INDICES.NOT_VISIBLE;
    },
    showAgentLogIndex: (): number => {
      return config.atrStreamName !== '' ? DISPLAY_INDICES.AGENT_LOG_TRANSFER_INDEX : DISPLAY_INDICES.NOT_VISIBLE;
    },
    showCallHistoryIndex: (): number => {
      return DISPLAY_INDICES.CALL_HISTORY_INDEX;
    },
    showManualOutboundCallIndex: (): number => {
      return DISPLAY_INDICES.MANUAL_OUTBOUND_CALL_INDEX;
    },
    showPaylineTransferIndex: (): number => {
      return config.paylineQuickConnect ? DISPLAY_INDICES.PAYLINE_TRANSFER : DISPLAY_INDICES.NOT_VISIBLE;
    },
  };

  return <CollapsibleCcpContext.Provider value={ctx}>{props.children}</CollapsibleCcpContext.Provider>;
}

export default CollapsibleCcpContext;
