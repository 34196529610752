/* eslint-disable @typescript-eslint/no-unused-vars */

import 'amazon-connect-streams';
import { createContext, useContext } from 'react';
import { IAgent, ICall, IQueue } from '../models/websocketInterfaces';
/**
 * Data Context supplies data to the application that comes from the Websockets
 */
export interface IConnectDataContext {
  agents: IAgent[];
  callLog: ICall[];
  queues: IQueue[];
  phoneDirectoryConfigChange: number;
  sendWebsocketData: (payload: any) => void;
}

const defaultContext: IConnectDataContext = {
  agents: [],
  callLog: [],
  queues: [],
  phoneDirectoryConfigChange: 0,
  sendWebsocketData: () => {},
};
const ConnectDataContext = createContext(defaultContext);

ConnectDataContext.displayName = 'ConnectConfigDataContext'; // used in dev tools
export { ConnectDataContext };

export const useDataContextProvider = () => {
  const context = useContext(ConnectDataContext);
  if (!context) {
    throw new Error('Cannot use DataContext without Provider');
  }
  return context;
};
