/* eslint-disable @typescript-eslint/typedef */
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState, Profiler, useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { CallContext } from '../../contexts/callContext';
import { useDataContextProvider } from '../../contexts/dataContext';
import { IPhoneDirItem } from '../../models/websocketInterfaces';
import { getLogger } from '../../services/loggingService';
import DialerButton from './dialerButton';
import { useDialerContextProvider, IDialerProps } from '../../contexts/dialerContext';
import { logProfilerData } from '../../utils/profilerUtils';
import RestApiContext from '../../contexts/restApiContext';
import { DialerSource } from '../../models/dialer';

const logger = getLogger('PhoneDirectory');
interface IProps {
  phoneDirectory: IPhoneDirItem[];
}

const customSearchFunction = (options: any) => {
  return options;
};
const useStyles = makeStyles(() => ({
  callButton: {
    marginTop: '10px',
  },
}));

interface IDialerNumber {
  number: string;
  callImmediate: boolean;
}
export function PhoneDirectory() {
  const [phoneDirectoryItems, setPhoneDirectoryItems] = useState<IPhoneDirItem[]>([]);
  const [fullPhoneDirectory, setFullPhoneDirectory] = useState<IPhoneDirItem[]>([]);
  const [directorySearchTerm, setDirectorySearchTerm] = useState<string>('');
  const [dialerNumber, setDialerNumber] = useState<IDialerNumber>({
    number: '',
    callImmediate: false,
  });

  const classes = useStyles();

  const api = useContext(RestApiContext);
  const { currentClientIdentifier, currentQueueName } = useContext(CallContext);
  const { phoneDirectoryConfigChange } = useDataContextProvider();
  const { setDialerProps } = useDialerContextProvider();

  useEffect(() => {
    (async () => {
      logger.debug('Check Phone Directory ');
      const clientIdentifier = (currentClientIdentifier === '' ? null : currentClientIdentifier) ?? 'default';
      const queueName = (currentQueueName === '' ? null : currentQueueName) ?? 'default';
      logger.debug('Fetching New Phone Directory');
      try {
        setFullPhoneDirectory(await api.getTenantDirectoryByQueue(clientIdentifier, queueName));
      } catch (error) {
        console.error(`Cannot set phone directory for Client:${clientIdentifier} and Queue: ${queueName}`);
        console.error(error.message);
      }
    })();
  }, [api, currentClientIdentifier, currentQueueName, phoneDirectoryConfigChange]);

  useEffect(() => {
    logger.debug('Set Directory from compiled items');
    if (!directorySearchTerm) {
      if (fullPhoneDirectory.length < 500) {
        setPhoneDirectoryItems(fullPhoneDirectory);
      } else {
        setPhoneDirectoryItems([]);
      }
    } else {
      const search = directorySearchTerm.toLowerCase();
      const filtered = fullPhoneDirectory.filter((item: any) => {
        return (
          item.name.toLowerCase().includes(search) ||
          item.ani.toLowerCase().includes(search) ||
          (item.ext && item.ext.toLowerCase().includes(search))
        );
      });
      setPhoneDirectoryItems(filtered);
    }
  }, [directorySearchTerm, fullPhoneDirectory]);

  const getContacts = useCallback(() => {
    if (phoneDirectoryItems.length === 0) {
      return [];
    }
    return phoneDirectoryItems.map((item: IPhoneDirItem, index: number) => {
      let number = item.ani;
      if (item.ext) {
        number += `x${item.ext}`;
      }

      return {
        key: `phone-directory-${item.name}-${index + 1}`,
        text: number,
        value: number,
        content: (
          <ListItem dense={true}>
            <ListItemIcon>
              <Avatar onClick={() => clickToCall(number)}>
                <CallIcon color="primary" />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={item.name} secondary={`${number}`} />
          </ListItem>
        ),
      };
    });
  }, [phoneDirectoryItems]);

  useEffect(() => {
    const immediateDial = dialerNumber.number && dialerNumber.number !== '' ? dialerNumber.callImmediate : false;

    const outbound: Omit<IDialerProps, 'isDisabled'> = {
      source: DialerSource.PhoneDirectory,
      number: dialerNumber.number,
      immediateDial,
      queueArn: undefined,
      clearCall,
    };
    console.log('SETTING DIALER PROPS: ', outbound);
    setDialerProps(outbound);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialerNumber]);

  const clickToCall = (number: string) => {
    logger.debug('Call with immediateDial = true: ' + number);
    setDialerNumber({
      number: number,
      callImmediate: true,
    });
    //triggers handleChange()
  };

  const handleChange = useCallback((_event: unknown, data: any): void => {
    logger.debug('Set Call Number', data.value);
    setDialerNumber(current => {
      if (current.callImmediate === true) {
        return current;
      }
      return {
        number: data.value,
        callImmediate: false,
      };
    });
  }, []);

  const getNoResultsMessage = useCallback(() => {
    if (phoneDirectoryItems.length === 0 && !directorySearchTerm) {
      //directorySearchTerm  was searchQuery
      return 'Please enter a search term...';
    } else if (phoneDirectoryItems.length === 0 && directorySearchTerm) {
      //directorySearchTerm  was searchQuery
      return 'Search returned no results, try again!';
    } else {
      return 'No results!';
    }
  }, [phoneDirectoryItems, directorySearchTerm]);

  const handleSearchChange = debounce((event: any, data: any) => {
    // setSearchQuery(data.searchQuery);
    logger.debug('Update search term: ' + data.searchQuery);
    setDirectorySearchTerm(data.searchQuery);
  }, 350);

  const clearCall = useCallback(() => {
    setDialerNumber({
      number: '',
      callImmediate: false,
    });
  }, []);

  return useMemo(
    () => (
      <div id="outbound-call-options">
        <Dropdown
          clearable
          search={customSearchFunction}
          noResultsMessage={getNoResultsMessage()}
          selection
          fluid
          onChange={handleChange}
          onSearchChange={handleSearchChange}
          value={dialerNumber.number}
          options={getContacts()}
          placeholder="Select number or click icon to call"
        />
        <Profiler id="DialerButton" onRender={logProfilerData}>
          <DialerButton classes={classes} />
        </Profiler>
      </div>
    ),
    [getNoResultsMessage, handleSearchChange, dialerNumber, getContacts, classes, handleChange]
  );
}
