/* eslint-disable @typescript-eslint/no-unused-vars */

import 'amazon-connect-streams';
import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IAgent, ICall, IQueue } from '../models/websocketInterfaces';
import { CallContext } from './callContext';
import { ConnectDataContext, IConnectDataContext } from './dataContext';
import { getLogger } from '../services/loggingService';
import AppContext from './appContext';
import TokenContext from './tokenContext';
import { useInterval } from 'usehooks-ts';

const logger = getLogger('ConnectDataContextProvider');

const ConnectDataContextProvider: FC<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {
  // call log data
  const [callLog, setCallLog] = useState<ICall[]>([]);
  // queue metrics data
  const [queues, setQueues] = useState<IQueue[]>([]);
  // agents data
  const [agents, setAgents] = useState<IAgent[]>([]);
  // web socket data
  const ws = useRef<WebSocket>();
  // refer to web socket current value - used only for triggering re-render
  const [currentWs, setCurrentWs] = useState<WebSocket>();
  // phone directory data
  const [phoneDirectoryConfigChange, setPhoneDirectorConfigChange] = useState(0);
  // agent department
  const [currentAgentDepartment, setCurrentAgentDepartment] = useState<string>('');
  // web socket URL extracted from current AppContext context
  const {
    config: { wssUrl },
  } = useContext(AppContext);
  const { token } = useContext(TokenContext);
  // user data extracted from call context
  const { user } = useContext(CallContext);

  /**
   * Helper function - closes current web socket
   * @return
   */
  const closeWebsocket = () => {
    // check whether web socket reference is present and whether it is already in closed or closing state
    if (ws.current && ![ws.current.CLOSED, ws.current.CLOSING].includes(ws.current.readyState)) {
      ws.current.close();
      logger.debug('Closed connection');
    }
  };

  /**
   * Effect - Sets cleanup function when the user leaves the app and components unmount
   * Closes web socket when the component is unmounted
   */
  useEffect(() => {
    return () => {
      logger.debug('UNMOUNTING COMPONENT - Close websocket ');
      closeWebsocket();
    };
  }, []);

  /**
   * Helper function - starts web socket and sets it as current
   * @param username:string - agent username
   * @param token:string - ccpToken value originally retrieved from the initial outbound whisper call
   * @param payload:any - optional payload when data needs to be send over the web socket
   * @depends on currentAgentDepartment, user, callLog, agents, queues
   * @returns
   */
  const startWSS = useCallback(
    (username: string, token: string, payload?: any) => {
      const subscribe = { action: 'init' };
      const params = {
        AgentUsername: username,
        token,
      };
      console.log(`Start websocket to ${wssUrl} as ${username}`);

      // web socket URL
      let webSocketURL;
      try {
        // set web socket URL based on params
        webSocketURL = [wssUrl, new URLSearchParams(params).toString()].join('?');

        // create new web socket and set it as current
        ws.current = new WebSocket(webSocketURL);

        // update currentWs state
        setCurrentWs(ws.current);

        // define on-open behavior of the web socket
        ws.current.onopen = () => {
          (async () => {
            // transmit data to web socket - send init action
            await ws.current.send(JSON.stringify(subscribe));
            console.log('Subscribing to WSS');

            if (payload) {
              // transmit data to web socket - send payload
              await ws.current.send(JSON.stringify(payload));
              logger.debug('WSS::Payload sent', payload);
            }
          })();
        };

        // define on-close behavior of web socket
        ws.current.onclose = () => {
          console.log('WSS has lost connection.');
          ws.current.close();
        };

        // define on-error behavior of the web socket
        ws.current.onerror = e => {
          console.error('There was an error', e);
        };

        // set current web socket as WBS TODO: WBS is not explicitly used anywhere?
        (window as any).WBS = ws.current;
      } catch (error) {
        console.error('Could not start websocket: ' + webSocketURL);
        console.error('This connection will not start until a token is passed from a valid incoming call.');
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAgentDepartment, user, callLog, agents, queues]
  );

  /**
   * Effect - Starts web socket on page load
   * @depends on user.username, token, startWSS
   * @returns empty cleanup function
   */
  useEffect(() => {
    // start socket when username and token are available (required parameters)
    if (!user.username || !token) return;

    // check if socket is already open
    if (ws.current && (ws.current.OPEN === ws.current.readyState || ws.current.CONNECTING === ws.current.readyState)) {
      console.log('Websocket is open: ' + ws.current.url);
      return;
    }

    // start web socket - no payload
    startWSS(user.username, token);

    // cleanup function set in a separate effect
    return () => {};
  }, [user.username, token, startWSS]);

  /**
   * Interval - Periodically attempt to reconnect socket if connection is lost
   * @returns numeric - identifier of created timer
   */
  useInterval(() => {
    // check for username and token
    let isUserLoggedIn = user.username && token;

    // check for current web socket
    let isWsInitialized = !!ws.current;

    // check for already open web socket
    let isWsOpen = isWsInitialized && [ws.current.CONNECTING, ws.current.OPEN].includes(ws.current.readyState);

    // if user is logged in and web socket is not open - start web socket connection
    if (isUserLoggedIn && isWsInitialized && !isWsOpen) {
      startWSS(user.username, token);
    }
  }, 5 * 1000);

  /**
   * Effect - Sets on-message behavior of current web socket
   * @depends on callLog, agents, queues, user, currentWs
   * @returns
   */
  useEffect(() => {
    // if current web socket exists
    if (ws.current) {
      // set on-message behavior
      ws.current.onmessage = (event: MessageEvent) => {
        // parse data coming as MessageEvent via the web socket connection
        console.log('INCOMING WEBSOCKET EVENT');
        const response = JSON.parse(event.data);
        logger.debug('WSS RESPONSE', response);

        // handle the data accordingly
        handleResponse(response);
      };
    }

    // bad practice: disable react errors because handleResponse is not defined as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callLog, agents, queues, user, currentWs]);

  /**
   * Helper function - Reads payload as body in backend WebsocketDefault lambda handler
   * @param payload:any - data that needs to be sent via the web socket
   * @depends on currentWs, user.username, token
   * @returns
   */
  const sendData = useCallback(
    async (payload: any) => {
      // check if current web socket is missing
      let isWsMissing = !ws.current || !(ws.current instanceof WebSocket);
      // check if current web socket connection is closed
      let isWsClosed = ws.current && [ws.current.CLOSED, ws.current.CLOSING].includes(ws.current.readyState);
      // check if current web socket connection is in connecting state
      let isWsConnecting = ws.current && ws.current.readyState === ws.current.CONNECTING;
      // check if current web socket connection is in open state
      let isWsOpen = ws.current && ws.current.readyState === ws.current.CONNECTING;

      // if web socket is missing or in closed state - start web socket connection
      if (isWsMissing || isWsClosed) {
        startWSS(user.username, token, payload);
        return;
      }
      // if web socket is still in connecting state - override on-open behavior
      if (isWsConnecting) {
        ws.current.onopen = () => {
          // send data to via the web socket connection once it gets to open state
          ws.current.send(JSON.stringify(payload));
          logger.debug('WSS::Payload sent', payload);
        };
        return;
      }

      try {
        // assume web socket is in open state (not closed and not connecting)
        if (isWsOpen) {
          // send data via the open web socket connection
          ws.current.send(JSON.stringify(payload));
        }
        // unexpected web socket state
        else {
          console.error('ERROR - not saved through socket:', payload);
          console.error('Could not save data through websocket - readyState is not OPEN');
        }
      } catch (error) {
        console.error(`Error sending payload to ${ws.current.url}: ${error.message}`, payload);
      }
    },

    // bad practice: disable react errors because startWSS is not defined as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentWs, user.username, token]
  );

  /**
   * Helper function - Handles data that comes from web socket connection
   * @param response:MessageEvent['data'] - data coming from web socket connection
   * @returns
   */
  const handleResponse = (response: MessageEvent['data']) => {
    logger.debug(' Find Response: ' + response.Table);
    switch (response.Table) {
      case 'QueueMetrics':
        handleQueues(response);
        break;
      case 'AgentMetrics':
        handleAgents(response);
        break;
      case 'AgentCallLog':
        handleCallLog(response);
        break;
      case 'PhoneDirectory':
        handlePhoneDirectory(response);
        break;
      default:
        console.log('Unrecognized message from websocket', response);
    }
  };

  /**
   * Helper function - Handles phone directory data coming from web socket connection
   * Phone directory component will get the data but changes to s3 config will be indicated by updating the config change number
   * @param response:MessageEvent['data'] - data coming from web socket connection
   * @returns
   */
  const handlePhoneDirectory = (response: MessageEvent['data']) => {
    // take no actions on init
    if (response.init) {
      return;
    }
    // update config change number (set new state)
    if (response.update) {
      setPhoneDirectorConfigChange(config => {
        return config + 1;
      });
    }
  };

  /**
   * Helper function - Handles queue metrics data coming from web socket connection
   * @param response:MessageEvent['data'] - data coming from web socket connection
   * @depends on queues, user
   * @returns
   */
  const handleQueues = useCallback(
    (response: MessageEvent['data']) => {
      // if type of data is init - data contains Items which is a list of queues
      if (response.init) {
        // update queue state with relevant queues only
        setQueues(response.Items.filter(isRelevantQueue));
        return;
      }

      // assume type of data is update - data contains a single queue
      logger.debug('Update Queue', response.Queue);

      // if the queue is not relevant - take no action
      if (!isRelevantQueue(response)) return;

      // search for the queue in current queues array
      const index = queues.findIndex((queue: IQueue) => queue.Queue === response.Queue);

      // queue not found - add the queue to the array and update queues state
      if (index === -1) {
        setQueues([response, ...queues]);
        return;
      }

      // queue found - replace the reference and update queues state
      setQueues(replaceAndReturn(queues, index, response));
    },

    // bad practice: disable react errors because isRelevantQueue is not defined as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queues, user]
  );

  /**
   * Helper function - Handles agent metrics data coming from web socket connection
   * @param response:MessageEvent['data'] - data coming from web socket connection
   * @depends on agents, currentAgentDepartment, user
   * @returns
   */
  const handleAgents = useCallback(
    (response: MessageEvent['data']) => {
      // if type of data is init - data contains Items which is a list of agents
      if (response.Items) {
        logger.debug(
          'AgentMetrics::Initiating agent log from Items for dpt: ' + currentAgentDepartment,
          response.Items
        );

        // if current department is not set - find department first
        if (!currentAgentDepartment) {
          findMyDepartmentFirst(response.Items);
          return;
        }

        // update agents state with online agents that are relevant, exclude current user
        setAgents(
          noOfflineAgents(response.Items.filter((a: IAgent) => isRelevantAgent(a) && a.AgentUsername !== user.username))
        );

        return;
      }

      // assume type of data is update - check whether it is for current user and take no action
      if (response.AgentUsername === user.username) {
        console.log('AgentMetrics::Record is for current agent');
        return;
      }

      logger.debug(
        `AgentMetrics::Process AgentUsername: ${response.AgentUsername}, Status: ${
          response.Status
        }, Dept: ${getAgentDepartment(response)}`
      );

      // if the agent is not relevant - take no action
      if (!isRelevantAgent(response)) return;

      let newAgentsState: IAgent[];

      // search for the agent in current agents array
      const index = agents.findIndex((agent: IAgent) => agent.AgentUsername === response.AgentUsername);

      // agent not found - add the agent to the array
      if (index === -1) {
        newAgentsState = [response, ...agents];
      }
      // agent found - replace the reference
      else {
        newAgentsState = replaceAndReturn(agents, index, response);
      }

      // update agents state excluding offline agents
      console.log('AgentMetrics::Update Agents: ', agents);
      setAgents(noOfflineAgents(newAgentsState));
    },

    // bad practice: disable react errors because findMyDepartmentFirst, isRelevantAgent and noOfflineAgents are not defined as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agents, currentAgentDepartment, user]
  );

  /**
   * Helper function - Replaces an item in an array with a new item for a given position
   * @param array:any[] - array to replace the item in
   * @param index:number - current position of the item in the array (determined before the call of this function and based on item properties)
   * @param newValue:any - new item to replace the old one with
   * @returns any[] - array that is a copy of the original one (has new reference) and but has the new item at the given position
   */
  const replaceAndReturn = (array: any[], index: number, newValue: any): any[] => {
    // create a copy of the original array - acquire new reference
    const newArray = array.slice();
    // replace the item at the given position with the new item
    newArray.splice(index, 1, newValue);
    // return the resulting array
    return newArray;
  };

  /**
   * Helper function - Filters an array of agents by including only those that are considered online
   * @param agents:IAgent[] - list of agents
   * @returns IAgent[] - list of filtered by status agents
   */
  const noOfflineAgents = (agents: IAgent[]): IAgent[] => {
    // return only agents that are online
    return agents.filter((agent: IAgent) => isOnlineAgent(agent));
  };

  /**
   * Helper function - Handles call logs data coming from web socket connection
   * @param response:MessageEvent['data'] - data coming from web socket connection
   * @depends on callLog
   * @returns
   */
  const handleCallLog = useCallback(
    (response: MessageEvent['data']) => {
      // if type of data is init - data contains Items which is a list of call logs
      if (response.init) {
        // sort the list of call logs by date and update callLog state
        setCallLog(dateSort(response.Items));
        return;
      }

      // assume type of data is update - data contains a single call log record - add it on top of current list of call logs and update callLog state
      setCallLog([response, ...callLog]);
    },
    [callLog]
  );

  /**
   * Helper function - Creates a copy of a given list of call logs and sorts it by connected timestamp descending
   * @param calls:ICall[] - list of call logs
   * @returns ICall[] - sorted by time list of call logs (new reference acquired)
   */
  const dateSort = (calls: ICall[]): ICall[] => {
    // create a copy of the provided list of call logs, return the new list sorted by connected timestamp descending
    return calls.slice().sort((callA: ICall, callB: ICall): number => {
      return new Date(callA.ConnectedTimestamp) < new Date(callB.ConnectedTimestamp) ? 1 : -1;
    });
  };

  /**
   * Helper function - Check whether a queue is relevant to current user
   * @param queue:IQueue - queue to check
   * @returns boolean - true if the queue is relevant to the user, false - otherwise
   */
  const isRelevantQueue = (queue: IQueue) => {
    // check if the queue is in the list of queues associated with current user
    return user.queues.includes(queue.Queue);
  };

  /**
   * Helper function - Chech whether an agent is relevant to current user
   * @param agent:IAgent - agent to check
   * @returns boolean - true if the agent is relevant to the user, false - otherwise
   */
  const isRelevantAgent = (agent: IAgent) => {
    // get agent department and compare it with current user's department
    return currentAgentDepartment === getAgentDepartment(agent);
  };

  /**
   * Helper function - Checks if an agent is considered online
   * @param agent:IAgent - agent to check
   * @returns boolean - true if the agent is considered online, false - otherwise
   */
  const isOnlineAgent = (agent: IAgent) => {
    // agent is considered online if not in offline status
    return agent.Status !== 'Offline';
  };

  /**
   * Helper function - Finds an agent is a list of agents to determine the current department
   * @param agents:IAgent[] - list of agents to search in
   * @returns
   */
  const findMyDepartmentFirst = (agents: IAgent[]) => {
    // find current user records in the list of agents filtering by username and existing department
    logger.debug('Look for agent: ' + user.username);
    let currentUserRecords: IAgent[] = agents.filter((a: IAgent) => {
      return user.username === a.AgentUsername && getAgentDepartment(a);
    });

    // if no record found - take no action
    if (!currentUserRecords || !currentUserRecords.length) return;

    // at least one agent record found - take the latest one
    logger.debug(`AGENTMETRICS::Found ${currentUserRecords.length} update records for agent ` + user.username, agents);

    // update currentAgentDepartment state with the department of the last record found
    let newDepartment: string = getAgentDepartment(currentUserRecords[currentUserRecords.length - 1]);
    setCurrentAgentDepartment(newDepartment);
    logger.debug('AGENTMETRICS::Setting department to:  ' + newDepartment);

    // update agents state with only relevant agents that are not offline
    setAgents(
      noOfflineAgents(
        agents.filter((a: IAgent) => {
          // TODO: this code repeats
          // filter agents list so that only agents from the same department are listed, excluding current user
          return user.username !== a.AgentUsername && newDepartment === getAgentDepartment(a);
        })
      )
    );
  };

  /**
   * Helper function - Returns an agent's department formed by the hierarchy levels
   * @param agent:IAgent - agent
   * @returns string - agent department
   */
  const getAgentDepartment = (agent: IAgent): string => {
    // form agent department by concatenating level 1 and level 2 hierarchy
    return [agent.HierarchyLevel1, agent.HierarchyLevel2].join('/');
  };

  // memorized value - output for ConnectDataContextProvider
  // depends on agents, callLog, queues, phoneDirectoryConfigChange, currentAgentDepartment
  const returnValue: IConnectDataContext = useMemo(
    () => ({
      agents: agents,
      callLog: callLog,
      queues: queues,
      phoneDirectoryConfigChange: phoneDirectoryConfigChange,
      sendWebsocketData: sendData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agents, callLog, queues, phoneDirectoryConfigChange, currentAgentDepartment]
  );

  return <ConnectDataContext.Provider value={returnValue}>{props.children}</ConnectDataContext.Provider>;
};

// set display name for dev tools and export ConnectDataContextProvider
ConnectDataContext.displayName = 'ConnectDataContext';
export { ConnectDataContextProvider };
