/* eslint-disable @typescript-eslint/no-unused-vars */
import 'amazon-connect-streams';
import { createContext } from 'react';
import { IUser } from '../models/websocketInterfaces';

/**
 * Call Context supplies data to the App that comes from the connect streams objects
 */
export interface ICallContext {
  /**
   * Initialize Amazon Connect Streams
   */
  initialize: (container: HTMLElement) => void;
  /**
   * Terminate Streams
   */
  terminate: () => void;
  initialized: boolean;
  contact: connect.Contact | undefined;
  agent: connect.Agent | undefined;
  onACW: boolean;
  currentClientIdentifier: string;
  currentQueueName: string;
  contactId: string;
  availableAgentEndpoints: connect.Endpoint[];
  availableQueueEndpoints: connect.Endpoint[];
  user: IUser;
}
const CallContext = createContext<ICallContext>(null);

export { CallContext };
