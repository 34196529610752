/**
 * Provides promisified implementations of streams methods and higher-order helper functions
 *
 * Loosely based off of: https://voicefoundry.com/aws_connect_streams_api/
 */

import { getLogger } from './services/loggingService';

const logger = getLogger('connectExtensions');

/**
 * Start an outbound call and send extension digits if provided.
 * @param agent
 * @param endpoint
 * @param extension
 */
export async function placeOutboundCall(
  agent: connect.Agent,
  phoneNumber: string,
  queueARN?: string,
  extension?: string
): Promise<{ connection: connect.BaseConnection; contact: connect.Contact }> {
  logger.debug('placeOutboundCall', { phoneNumber, extension, queueARN });

  const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
  const result = await addAgentConnection(agent, endpoint, queueARN);

  if (extension) {
    await sendDigits(result.connection, extension);
    logger.debug('extension sent');
  }

  logger.debug('placeOutboundCall complete');
  return result;
}

/**
 * Puts current contact on hold and add a connection. Sends digits if provided.
 * @param contact
 * @param endpoint
 * @param extension
 */
export async function addExternalConnection(
  contact: connect.Contact,
  phoneNumber: string,
  extension?: string
): Promise<void> {
  await holdConnection(contact);
  const connection = await addContactConnection(contact, phoneNumber);
  if (extension) {
    await sendDigits(connection, extension);
  }

  logger.debug('addExternalConnection complete');
}

/**
 * Dials digits and waits 1 second for commas
 */
export async function sendDigits(connection: connect.BaseConnection, extension: string): Promise<void> {
  logger.debug(`sending extension: ${extension}`);
  for (const char of extension) {
    if (char === ',') {
      await waitSeconds(1);
    } else {
      await sendDigitsAsPromise(connection, char);
    }
    await waitSeconds(0.1);
  }
}

function sendDigitsAsPromise(connection: connect.BaseConnection, digits: string): Promise<void> {
  logger.debug(`sendDigitsAsPromise: ${digits}`);
  return new Promise((resolve, reject) => {
    connection.sendDigits(digits, {
      success: resolve,
      failure: e => {
        logger.error('sendDigitsAsPromise::', e);
        reject(e);
      },
    });
  });
}

/**
 * Initiates a cold transfer.
 * If 3rd party picks up - agent disconnects
 * If 3rd party does not pick up - customer is left on-hold and agent stays connected.
 */
export async function coldTransferToDestination(contact: connect.Contact, phoneNumber: string): Promise<void> {
  await holdConnection(contact);
  await addContactConnection(contact, phoneNumber);
  await beginConference(contact);
  await disconnectAgent(contact);
  logger.debug('Cold transfer complete');
}

/**
 * Helper for finding the right connection
 */
export function getConnectionByType(
  contact: connect.Contact,
  connectionType: connect.ConnectionType
): connect.BaseConnection | undefined {
  return contact.getConnections().find(x => x.getType() === connectionType);
}

/**
 * Holds a connection and provides error handling.
 */
export function holdConnection(contact: connect.Contact): Promise<void> {
  return new Promise((resolve, reject) => {
    const connection = contact.getInitialConnection();
    if (!connection.isOnHold()) {
      connection.hold({
        success: () => {
          resolve();
        },
        failure: (e: string) => {
          logger.error('holdConnection::', e, connection.getAddress().phoneNumber);
          reject(e);
        },
      });
    } else {
      resolve();
    }
  });
}

/**
 * Wrapper method for adding connections with error handling
 * Method returns connection once teh call is connected.
 */
export function addContactConnection(contact: connect.Contact, phoneNumber: string): Promise<connect.BaseConnection> {
  const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
  return new Promise((resolve, reject) => {
    if (!contact) {
      reject('addContactConnection::contact parameter is required');
    }

    // Edge case: Its possible the agent has 2 connections to the same number, but different extensions
    const existingConn = contact.getConnections().find(x => x.getAddress().phoneNumber);

    contact.addConnection(endpoint, {
      success: async () => {
        for (let i = 0; i < 10; i++) {
          const newConn = contact
            .getConnections()
            .find(
              x =>
                x.getAddress().phoneNumber === phoneNumber &&
                (existingConn ? x.connectionId !== existingConn.connectionId : true)
            );
          if (newConn && newConn.isConnected()) {
            return resolve(newConn);
          }
          await waitSeconds(1);
        }
        reject('Waiting for connection timed out');
      },
      failure: function (e: string) {
        logger.error('addContactConnection::', e);
        reject(e);
      },
    });
  });
}

/**
 * Wrapper method for adding connections with error handling
 * Method returns connection once the call is connected.
 * queueARN is optional
 */
export async function addAgentConnection(
  agent: connect.Agent,
  endpoint: connect.Endpoint,
  queueARN?: string
): Promise<{ connection: connect.BaseConnection; contact: connect.Contact }> {
  await agentConnectAsPromise(agent, endpoint, queueARN);

  logger.debug('agent connected', endpoint);

  // Wait a bit for agent object to update.
  await waitSeconds(0.5);

  var bus = (connect.core as any).getEventBus();
  const voiceContacts = agent.getContacts(connect.ContactType.VOICE);
  if (voiceContacts.length !== 1) {
    // Error?
    throw new Error('addAgentConnection:: Contact not found. Should we extend the wait time?');
  }
  const contact = voiceContacts[0];

  return new Promise((resolve, reject) => {
    const subscription = bus.subscribe(contact.getEventName(connect.ContactEvents.REFRESH), function () {
      try {
        logger.debug('contact refresh', contact);
        // get current contact
        const outbound = getConnectionByType(contact, connect.ConnectionType.OUTBOUND);
        if (outbound) {
          if (outbound.isConnecting()) {
            // Still connecting, wait for next event
            return;
          }
          if (outbound.isConnected()) {
            // Clean up subscription.
            subscription.unsubscribe();
            resolve({
              connection: outbound,
              contact,
            });
          }
        } else {
          throw new Error('Outbound connection not found');
        }
      } catch (e) {
        logger.error('addAgentConnection::OnRefresh', e.message);
        subscription.unsubscribe();
        reject(e.message);
      }
    });
  });
}

/**
 * Promisified wrapper over agent.connect
 * queueARN is optional
 */
export function agentConnectAsPromise(
  agent: connect.Agent,
  endpoint: connect.Endpoint,
  queueARN?: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    agent.connect(endpoint, {
      queueARN: queueARN,
      success: resolve,
      failure: e => {
        logger.error('agentConnectAsPromise::', e);
        reject(e);
      },
    });
  });
}

/**
 * Begins conference between Agent, Incoming (customer) and Outgoing (thirdParty)
 */
export function beginConference(contact: connect.Contact): Promise<void> {
  return new Promise((resolve, reject) => {
    if (contact) {
      contact.conferenceConnections({
        success: () => {
          resolve();
        },
        failure: function (e: string) {
          logger.error('beginConference::', e);
          reject(e);
        },
      });
    } else {
      reject("beginConference::Couldn't begin conference, there's no conversation");
    }
  });
}

/**
 * Disconnects agent from contact
 */
export function disconnectAgent(contact: connect.Contact): Promise<void> {
  return new Promise((resolve, reject) => {
    if (contact) {
      var agentConnection = contact.getAgentConnection();
      if (agentConnection) {
        agentConnection.destroy({
          success: resolve,
          failure: e => {
            logger.error('disconnectAgent::', e);
            reject(e);
          },
        });
      }
    } else {
      reject('disconnectAgent::No contact provided.');
    }
  });
}

/**
 * Gets all phone contacts for Agent. QUEUE_CALLBACK does not return with voice contacts.
 */
export function getAgentContacts(agent: connect.Agent): connect.Contact[] {
  const voiceContacts = agent.getContacts(connect.ContactType.VOICE);
  const qcbContacts = agent.getContacts(connect.ContactType.QUEUE_CALLBACK);
  return voiceContacts.concat(qcbContacts);
}

export function waitSeconds(seconds: number): Promise<void> {
  logger.debug(`Waiting ${seconds} seconds`);
  return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

/**
 * Debugging method for printing connection information
 */
export function printConnections(contact: connect.Contact): void {
  const connections = contact.getConnections().map(c => ({
    connectionId: c.getConnectionId(),
    contactId: c.getContactId(),
    type: c.getType(),
    state: c.getState(),
    address: c.getAddress(),
  }));
  logger.debug('printConnections', connections);
}
