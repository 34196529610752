import { Button } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { addExternalConnection, getAgentContacts, placeOutboundCall } from '../../connectExtensions';
import AppContext from '../../contexts/appContext';
import { CallContext } from '../../contexts/callContext';
import { useDialerContextProvider } from '../../contexts/dialerContext';
import { DialerSource } from '../../models/dialer';
import { getLogger } from '../../services/loggingService';

const logger = getLogger('DialerButton');

interface IProps {
  classes: any;
}

const DialerButton = (props: IProps) => {
  const [buttonText, setButtonText] = useState<string>('Call');
  const [error, setError] = useState<string>('');
  const [initializingCall, setInitializingCall] = useState<boolean>(false);

  const { agent, contact } = useContext(CallContext);
  const { setAlert } = useContext(AppContext);
  const { dialerProps, setDialerProps } = useDialerContextProvider();

  useEffect(() => {
    logger.debug(dialerProps.source + '::INTIATE DIALER FOR: ');

    if (!dialerProps || dialerProps.source === DialerSource.DialerButton || dialerProps.number === '') {
      logger.debug(dialerProps.source + ':: No action taken.');
      return;
    }
    if (dialerProps.immediateDial) {
      (async () => {
        await clickToCall(dialerProps.number, dialerProps.queueArn);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialerProps]);

  useEffect(() => {
    if (!contact) return;
    logger.debug(dialerProps.source + '::Update Contact: ' + contact.getContactId());
    contact.onConnecting(() => handleConnecting());
    contact.onConnected(() => handleConnected());
    contact.onEnded(() => handleEnded());
    contact.onError(() => handleOnError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const onCallFailure = (err: any): void => {
    logger.debug(dialerProps.source + '::ERROR - CALL FAILURE', err);
    logger.error(err);
    setInitializingCall(false);
    err = JSON.parse(err);

    console.log('Error type  is: ' + err.type);
    if (err.type === 'QuotaExceededException') {
      console.log('Quota Exceeded exception - check dialerprops', dialerProps);
      // it would be better to no try the call twice, but both parents seem to be
      // causing this component to update when the immediate dial button is clicked.
      // TODO: find a better strategy
      if (dialerProps.immediateDial === true) {
        return;
      }
    }

    if (isAlreadyConnectedTo(dialerProps.number)) {
      logger.debug('Already connected to ' + dialerProps.number);
      return;
    }

    setError(err.message ? err.message : 'Outbound call error.  Please try again');
    setAlert(`Call Failed to ${dialerProps.number}`, 'error');

    setTimeout(() => {
      setError('');
    }, 3500);
  };
  const clearInputs = (): void => {
    if (dialerProps.clearCall) {
      dialerProps.clearCall();
    }
    setInitializingCall(false);
    setDialerProps({
      source: DialerSource.DialerButton,
      number: '',
      immediateDial: false,
      queueArn: undefined,
      clearCall: () => {},
    });
  };
  const onCallSuccess = (): void => {
    logger.debug(dialerProps.source + '::Dialer::Call was successful');
    setInitializingCall(false);
    if (dialerProps.clearCall) {
      dialerProps.clearCall();
    }
  };
  const handleConnecting = (): void => {
    logger.debug(dialerProps.source + '::Dialer::Handling CONNECTING');
    setButtonText('Connecting call ...');
  };
  const handleConnected = (): void => {
    logger.debug(dialerProps.source + '::Dialer::Call Connected');
    setButtonText('Connected');
  };
  const handleEnded = (): void => {
    setButtonText('Call');
    clearInputs();
  };
  const handleOnError = (): void => {
    logger.error('Handle contact OnError');

    clearInputs();
    setButtonText('Error calling');
    setError('Outbound call error');
    setAlert(`Connection Failed to ${dialerProps.number}`, 'error');
    setTimeout(() => {
      setButtonText('Call');
      setError('');
    }, 5000);
  };

  const isAlreadyConnectedTo = (phoneNumber: string): boolean => {
    if (!agent) {
      return false;
    }

    const agentContacts = agent.getContacts(connect.ContactType.VOICE);
    if (agentContacts.length === 0) {
      logger.debug('No connections to check');
      return false;
    }
    const connections = agentContacts[0].getConnections();
    for (const conn of connections) {
      logger.debug('CHECK CONNECTION: ' + conn.getEndpoint().phoneNumber, conn.getEndpoint());
      if (conn.getEndpoint().phoneNumber === phoneNumber) {
        logger.debug('Agent is already connected to ' + phoneNumber + ' Cannot dial the same phone number twice.');
        return true;
      }
    }
    return false;
  };

  const clickToCall = useCallback(
    async (phoneNumber: string, queueArn?: string) => {
      logger.debug(`${dialerProps.source}::START CALL FOR phone number: ${phoneNumber} and queueARN ${queueArn}`);

      if (!agent) return;

      const parts = phoneNumber.split('x');
      const number = parts[0].startsWith('+') ? parts[0] : `+${parts[0]}`;
      const extension = parts.length === 2 ? parts[1] : '';

      const agentContacts = getAgentContacts(agent);

      logger.debug(dialerProps.source + '::Dialer::Calling to endpoint: ', number);

      setInitializingCall(true);

      if (initializingCall) {
        logger.error('Already Initializing Call');
        return;
      }

      if (dialerProps.clearCall) {
        dialerProps.clearCall();
      }

      if (agentContacts.length === 0) {
        placeOutboundCall(agent, number, queueArn, extension).then(onCallSuccess).catch(onCallFailure);
      } else if (agentContacts.length === 1) {
        addExternalConnection(agentContacts[0], number, extension).then(onCallSuccess).catch(onCallFailure);
      } else {
        setInitializingCall(false);
        throw new Error('Already have 2 active voice contacts');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initializingCall, dialerProps, agent]
  );

  const callError = error ? <Alert severity={'error'}>{error}</Alert> : null;
  return useMemo(() => {
    return (
      <>
        <Button
          disabled={dialerProps.isDisabled || initializingCall}
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          className={props.classes.callButton}
          endIcon={<CallIcon />}
          onClick={() => clickToCall(dialerProps.number, dialerProps.queueArn)}>
          {buttonText}
        </Button>
        <div style={{ overflowWrap: 'break-word' }}>{callError}</div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialerProps, initializingCall, buttonText]);
};

export default DialerButton;
