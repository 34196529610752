import React, { useContext, useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';
import { CallContext } from './callContext';
import AppContext from './appContext';
import { placeOutboundCall } from '../connectExtensions';
import { Backdrop, LinearProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const loadToken = (): string => {
  const token = localStorage.getItem('ccpToken') || '';
  if (token) {
    const decoded = jwt.decode(token, { complete: true }) as any;
    const now = new Date().getTime();
    if (!decoded || decoded.payload.expires < now) {
      console.log('TOKEN EXPIRED');
      localStorage.removeItem('ccpToken');
    }
  }
  return token;
};

/**
 * Call this method when API Token becomes available
 */
const saveToken = (token: string): void => {
  localStorage.setItem('ccpToken', token);
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export interface ITokenContext {
  token: string;
  busy: boolean;
  refresh: () => Promise<string>;
}

export const TokenContext = React.createContext<ITokenContext>(null);

/**
 * Tracks and refreshes the token
 * This feels like it should be in a API context
 */
export function TokenContextProvider(props: { children: any }) {
  const classes = useStyles();
  const [token, setToken] = useState(loadToken());
  const [busy, setBusy] = useState(false);

  const {
    config: { tokenAuthNumber },
    setAlert,
  } = useContext(AppContext);
  const { agent, contact } = useContext(CallContext);

  const updateToken = (next: string) => {
    saveToken(next);
    setToken(next);
  };

  const refresh = async (): Promise<string> => {
    let ccpToken = '';
    try {
      setBusy(true);
      const result = await placeOutboundCall(agent, tokenAuthNumber);

      result.contact.onACW(c => {
        c.clear({});
        setBusy(false);
      });

      const ccpToken = result.contact.getAttributes().ccpToken?.value;
      if (ccpToken) {
        saveToken(ccpToken);
        setToken(ccpToken);
        setAlert('Authentication successful', 'success', 1000);
      } else {
        setAlert('Authentication error: Attribute named ccpToken is not available', 'error');
      }

      result.connection.destroy();
    } catch (error) {
      const message = typeof error === 'string' ? JSON.parse(error).message : error.message;
      setAlert(`Authentication error: ${message}`, 'error');
      setBusy(false);
    }
    return ccpToken;
  };

  useEffect(() => {
    if (agent && !token && tokenAuthNumber) {
      const statusType = agent.getStatus().type;
      if (statusType === connect.AgentStateType.OFFLINE || statusType === connect.AgentStateType.ROUTABLE) {
        refresh();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent, token]);

  /**
   * Refresh token automatically if its available
   */
  useEffect(() => {
    if (contact) {
      contact.onConnected(c => {
        const ccpToken = c.getAttributes().ccpToken?.value;
        if (ccpToken && ccpToken !== token) {
          updateToken(ccpToken);
        }
      });
    }
  }, [contact]);

  const ctx: ITokenContext = {
    token,
    busy,
    refresh,
  };

  return (
    <TokenContext.Provider value={ctx}>
      {props.children}
      <Backdrop className={classes.backdrop} open={busy}>
        <Alert severity={'info'}>
          Authenticating. Please wait...
          <LinearProgress />
        </Alert>
      </Backdrop>
    </TokenContext.Provider>
  );
}

export default TokenContext;
